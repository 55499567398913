export const ModelFilterDropdown = [
  {
    label: "Water",
    value: [
      "tag.rhw",
      "tag.wmd",
      "tag.h2o",
      "lora.wmd",
      "lora.irrigator",
      "bulk.wmd",
      "rom.ptw",
    ],
  },
  {
    label: "Electricity",
    value: ["tag.rhe", "tag.opt", "lora.abbelec", "rom.pte", "rom.rhe"],
  },
  {
    label: "Temperature",
    value: ["tag.tmp", "tag.tmo", "lora.nanotag", "lora.tmp", "shv.tmp"],
  },
  {
    label: "Gas",
    value: ["tag.dry"],
  },
  {
    label: "Level",
    value: ["tag.lvl"],
  },
  {
    label: "QR Code",
    value: ["qrw.man", "qre.man", "qre.pre", "qrw.pre", "qrg.pre"],
  },
];
