import { forwardRef, useEffect, useState } from "react";
import DateRangePicker from "@wojtekmaj/react-daterange-picker";
import { InputHTMLAttributes } from "react";
import "@wojtekmaj/react-daterange-picker/dist/DateRangePicker.css";
import "react-calendar/dist/Calendar.css";
import { MdCalendarToday, MdClear } from "react-icons/md";

// LooseValue represents the types supported by DateRangePicker
type LooseValue = Date | [Date, Date] | null;

interface DateRangePickerProps
  extends Omit<InputHTMLAttributes<HTMLInputElement>, "value" | "onChange"> {
  id: string;
  extra?: string;
  value?: LooseValue; // Accept default value from the parent
  onChange?: (value: LooseValue) => void; // Correct onChange type
  sizes?: "lg" | "md" | "sm" | "xs";
  minDate?: Date;
  maxDate?: Date;
  state?: "error" | "success"; // Specific states for styling
  iconOnly?: boolean;
  color?: "primary" | "secondary";
  rounded?: string;
  loading?: boolean;
  variant?: "solid" | "outline" | "filter";
}

const DateRangePickerField = forwardRef<HTMLInputElement, DateRangePickerProps>(
  (
    {
      id,
      extra,
      sizes = "lg",
      minDate,
      maxDate,
      state,
      disabled,
      readOnly,
      iconOnly = false,
      value: defaultValue, // value from parent as defaultValue
      color = "primary",
      rounded = "rounded-2xl",
      loading = false,
      variant = "outline",
      onChange,
    },
    ref
  ) => {
    // State for managing date range within the component
    const [value, setValue] = useState<LooseValue>(defaultValue || null);

    useEffect(() => {
      // Update local state when parent value changes
      if (defaultValue) {
        setValue(defaultValue);
      }
    }, [defaultValue]);

    const handleChange = (selectedValue: LooseValue) => {
      setValue(selectedValue);
      if (onChange) {
        onChange(selectedValue);
      }
    };

    const isDisabled = disabled || loading || readOnly;

    const colorClasses = {
      primary: {
        filter: `text-primary bg-gray-50`,
        solid: `text-primary bg-gray-50/10`,
        outline: `border border-brand-700 text-primary bg-transparent`,
      },
      secondary: {
        filter: `text-secondary bg-gray-50`,
        solid: `text-secondary bg-gray-50/10`,
        outline: `border border-secondary text-secondary bg-transparent`,
      },
    };

    const iconColorClasses = {
      primary: "text-primary",
      secondary: "text-secondary",
    };

    const sizeClasses = {
      lg: "h-12 px-6 text-txt-lg",
      md: "h-10 px-4 text-txt-md",
      sm: "h-8 px-3 text-txt-sm",
      xs: "h-6 px-2 text-txt-xs",
    };

    const stateClasses = {
      error: "outline-red-500 text-red-500 placeholder:text-red-500",
      success: "outline-green-500 text-green-500 placeholder:text-green-500",
    };

    const iconClass = {
      lg: "h-12 w-12 text-txt-lg",
      md: "h-10 w-10 text-txt-md",
      sm: "h-8 w-8 text-txt-sm",
      xs: "h-6 w-6 text-txt-xs",
    };

    return (
      <div
        className={`flex w-full items-center justify-between gap-2  ${rounded} ${
          iconOnly ? iconClass[sizes] : sizeClasses[sizes]
        } ${
          iconOnly ? iconColorClasses[color] : colorClasses[color][variant]
        } ${state ? stateClasses[state] : "outline-none"} ${
          isDisabled
            ? "border-none bg-gray-50/50"
            : "hover:opacity-100 focus:opacity-100"
        }`}
      >
        {!iconOnly &&
          (value &&
          Array.isArray(value) &&
          value[0] != null &&
          value[1] != null ? (
            <div className="truncate">
              {new Date(value[0]).toLocaleDateString()} -{" "}
              {new Date(value[1]).toLocaleDateString()}
            </div>
          ) : (
            <span className="truncate text-gray-500">Select date</span>
          ))}

        <div className="flex items-center gap-2">
          {!iconOnly && value && (
            <MdClear
              onClick={(e) => {
                e.stopPropagation();

                handleChange(null);
              }}
              className={`-mr-2 cursor-pointer`}
            />
          )}

          <DateRangePicker
            id={id}
            onChange={handleChange}
            value={value}
            clearIcon={null}
            showLeadingZeros
            calendarIcon={
              <MdCalendarToday
                className={`${
                  iconOnly ? `${iconClass[sizes]} text-secondary` : ""
                }`}
              />
            }
            className={`${
              iconOnly ? ` ${iconClass[sizes]}` : "-mr-2 text-primary"
            }`}
            maxDate={maxDate}
            minDate={minDate}
          />
        </div>
      </div>
    );
  }
);

export default DateRangePickerField;
