import { t } from "i18next";
import { MenuRoutePaths } from "models/constants/routes";
import { GoArrowRight } from "react-icons/go";
import { useNavigate } from "react-router-dom";
import DashboardCard from "widgets/cards/dashboard";
import CircleButton from "widgets/shared/buttons/circle-button";

interface GeneralProps {
  count: number;
}

const Sites: React.FC<GeneralProps> = ({ count }) => {
  const navigate = useNavigate();

  const handleRoute = () => {
    navigate(`/admin${MenuRoutePaths.SITES}`);
  };

  return (
    <DashboardCard
      title={t("dashboard.card.sites.title")}
      bgColor="bg-brand-300"
      textColor="text-secondary"
      extra="max-h-52"
    >
      <div className="flex-grow"></div>
      <div className="heading-4xl mt-4 text-secondary">{count}</div>
      <div className="absolute bottom-4 right-2">
        <CircleButton
          label={t("dashboard.card.action", { context: "sites" })}
          size="xs"
          variant="ghost"
          color="secondary"
          icon={GoArrowRight}
          iconPosition="right"
          onClick={handleRoute}
        />
      </div>
    </DashboardCard>
  );
};

export default Sites;
