import { useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";

import GenericCard from "widgets/cards/generic";
import WaterRateForm from "widgets/forms/sites/rates/water";
import SewerageRateForm from "widgets/forms/sites/rates/sewerage";
import ElectricityRateForm from "widgets/forms/sites/rates/electricity";
import LabeledField from "providers/labeledField";
import { setLoading } from "store/appSlice";
import { ratesRateGet, ratesRateUpdate } from "api/rates";
import GenericButton from "widgets/shared/buttons/generic";
import { RateResponse, RatesUpdateBody } from "api/types/rates";
import OtherRateForm from "widgets/forms/sites/rates/other";
import { set } from "zod";

interface GeneralProps {
  rateId?: string;
  onUpdate?: () => void;
}

const RatesSetup: React.FC<GeneralProps> = ({ rateId, onUpdate }) => {
  const [loading, setLoading] = useState(true);
  const [dirty, setDirty] = useState(false);
  const [rates, setRates] = useState<RateResponse>(null);

  const [updatedRates, setUpdatedRates] = useState<RatesUpdateBody>(null);

  const fetchRate = async () => {
    setLoading(true);
    setRates(null);
    const { data, status, isLoading } = await ratesRateGet(rateId || "");
    setLoading(isLoading);
    if (status && status === 200) {
      // console.log(data);
      setRates({ ...data });
    }
  };

  useEffect(() => {
    if (rateId) {
      fetchRate();
    }
  }, [rateId]);

  const handleUpdate = (formData: any, type: string) => {
    setDirty(true);
    console.log(formData);
    if (type === "water") {
      setUpdatedRates((prev) => ({
        ...prev,
        rateId: rateId,
        water: formData,
      }));
    } else if (type === "sewerage") {
      setUpdatedRates((prev) => ({
        ...prev,
        rateId: rateId,
        sewerage: formData,
      }));
    } else if (type === "electricity") {
      setUpdatedRates((prev) => ({
        ...prev,
        rateId: rateId,
        electricity: formData,
      }));
    } else if (type === "other") {
      setUpdatedRates((prev) => ({
        ...prev,
        rateId: rateId,
        other: formData.other,
      }));
    }
  };

  const updateRate = async () => {
    const { data, status, isLoading } = await ratesRateUpdate(updatedRates);

    if (status && status === 200) {
      setUpdatedRates(null);
      fetchRate();
      setDirty(false);
    }
  };

  const handleDiscard = () => {
    setLoading(true);
    setDirty(false);
    setUpdatedRates(null);
    const oldRates = { ...rates };

    // Set rates to null temporarily
    setRates(null);

    // Restore old rates after a short delay
    setTimeout(() => {
      setRates(oldRates);
      setLoading(false);
    }, 200); // Adjust the delay as necessary (200 ms in this example)
  };

  const rateKey = useMemo(() => `${rateId}`, [rateId]);

  return (
    <>
      <div>
        {dirty ? (
          <div className="mt-0 flex justify-end gap-2">
            <GenericButton
              text={"Save"}
              size="md"
              onClick={updateRate}
              color="secondary"
            />
            <GenericButton
              text={"Discard"}
              size="md"
              variant="outline"
              onClick={handleDiscard}
              color="secondary"
            />
          </div>
        ) : (
          <></>
        )}
      </div>
      <div className="grid max-h-full grid-cols-1 gap-4 sm:grid-cols-1 lg:grid-cols-4">
        <GenericCard
          title="Water Rates"
          description={"Display rates"}
          bgColor="bg-white"
          // extra="h-full"
          extraTitle="text-primary heading-md"
          extraDescription="text-tertiary text-txt-sm"
        >
          <div className=" flex h-full w-full flex-col gap-6 overflow-x-auto">
            {!loading && (
              <WaterRateForm
                key={`${rateKey}-water`}
                submit={(form) => handleUpdate(form, "water")}
                onClose={() => {}}
                defaultValues={rates?.water}
              />
            )}
          </div>
        </GenericCard>
        <GenericCard
          title="Sewerage Rates"
          description={"Display rates"}
          bgColor="bg-white"
          // extra="h-full"
          extraTitle="text-primary heading-md"
          extraDescription="text-tertiary text-txt-sm"
        >
          <div className="flex h-full w-full flex-col gap-6 overflow-x-auto">
            {!loading && (
              <SewerageRateForm
                key={`${rateId}-sewerage`}
                submit={(form) => handleUpdate(form, "sewerage")}
                onClose={() => {}}
                defaultValues={rates?.sewerage}
              />
            )}
          </div>
        </GenericCard>
        <GenericCard
          title="Electricity Rates"
          description={"Display rates"}
          bgColor="bg-white"
          // extra="h-full"
          extraTitle="text-primary heading-md"
          extraDescription="text-tertiary text-txt-sm"
        >
          <div className="flex h-full w-full flex-col gap-6 overflow-x-auto">
            {!loading && (
              <ElectricityRateForm
                key={`${rateId}-electricity`}
                submit={(form) => handleUpdate(form, "electricity")}
                onClose={() => {}}
                defaultValues={rates?.electricity}
              />
            )}
          </div>
        </GenericCard>
        <GenericCard
          title="Other Rates"
          description={"Display rates"}
          bgColor="bg-white"
          // extra="h-full"
          extraTitle="text-primary heading-md"
          extraDescription="text-tertiary text-txt-sm"
        >
          <div className="flex h-full w-full flex-col gap-6 overflow-x-auto">
            {!loading && (
              <OtherRateForm
                key={`${rateId}-other`}
                submit={(form) => handleUpdate(form, "other")}
                onClose={() => {}}
                defaultValues={{ other: rates?.other }}
              />
            )}
          </div>
        </GenericCard>
      </div>
    </>
  );
};

export default RatesSetup;
