// Props
type GenericCardProps = {
  title?: string;
  description?: string;
  extra?: string;
  extraTitle?: string;
  extraDescription?: string;
  bgColor?: string;
  children?: React.ReactNode;
  topRight?: React.ReactNode;
};

const GenericCard = ({
  title,
  description,
  extra,
  extraTitle = "heading-lg text-primary",
  extraDescription = "text-txt-md text-primary",
  bgColor = "white",
  children,
  topRight,
}: GenericCardProps) => {
  return (
    <div
      className={`!z-5 relative flex w-full flex-col rounded-[20px] p-4 md:p-6 ${bgColor} ${extra} overflow-visible`}
    >
      <div className="mb-2 flex w-full flex-col">
        <div className="flex w-full flex-row justify-between md:items-start">
          <div className="flex flex-col">
            <h4 className={`${extraTitle}`}>{title}</h4>
            <p className={`${extraDescription} mt-1 `}>{description}</p>
          </div>

          <div className="mt-4 md:ml-4 md:mt-0">{topRight}</div>
        </div>
      </div>
      {children}
    </div>
  );
};

export default GenericCard;
