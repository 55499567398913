import { Amplify, Auth } from "aws-amplify";
import React, { createContext, useContext, useEffect, useState } from "react";
import { AwsConfigAuth } from "../config/auth";
import { useAppDispatch } from "util/hooks";
import appSlice, { setLoading } from "store/appSlice";
import { notifySimple } from "providers/toast";
import { useNavigate } from "react-router-dom";
import { SignUpFormType } from "widgets/forms/auth/signUp";
import {
  clearUserContext,
  setMfaEnabled,
  setUserContext,
} from "store/user/userSlice";
import { ChangePasswordFormType } from "widgets/forms/profile/changePassword";
import { clearAccountContext } from "store/account/accountSlice";

Amplify.configure({ Auth: AwsConfigAuth });

interface UseAuth {
  isAuthenticated: boolean;
  needsAccountSetup: boolean;
  username: string;
  signIn: (username: string, password: string) => void;
  signOut: () => void;
  signUp: (data: SignUpFormType) => void;
  confirmSignUp: (code: string) => void;
  confirmSignIn: (code: string) => void;
  deleteUser: () => void;
  resendSignUp: (username: string) => void;
  forgotPassword: (username: string) => void;
  forgotPasswordSubmit: (code: string, newPassword: string) => void;
  changePassword: (data: ChangePasswordFormType) => void;
  updateUserAttributes: (data: any) => Promise<any>;
  setupTOTP: () => Promise<Result>;
  verifyTotpToken: (code: string) => void;
  disableTotp: () => void;
  setNeedsAccountSetup: (value: boolean) => void;
}

interface Result {
  success: boolean;
  qrCode: string;
  secretCode: string;
}

type Props = {
  children?: React.ReactNode;
};

const authContext = createContext({} as UseAuth);

export const ProvideAuth: React.FC<Props> = ({ children }) => {
  const auth = useProvideAuth();
  return <authContext.Provider value={auth}>{children}</authContext.Provider>;
};

export const useAuth = () => {
  return useContext(authContext);
};

const useProvideAuth = (): UseAuth => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [needsAccountSetup, setNeedsAccountSetup] = useState(false);
  const [username, setUsername] = useState("");
  const [user, setUser] = useState(null);

  const setUserContextData = (
    user: {
      challengeName: string;
      preferredMFA: string;
      signInUserSession: {
        idToken: any;
        challengeName: any;
        refreshToken: { token: any };
      };
    },
    login: boolean
  ) => {
    const idToken = user.signInUserSession.idToken;
    dispatch(
      setUserContext({
        jwtAccessToken: idToken.jwtToken || "",
        jwtRefreshToken: user.signInUserSession.refreshToken.token || "",
        cognito_username: idToken.payload["cognito:username"] || "",
        email: idToken.payload.email || "",
        name: idToken.payload.name || "",
        family_name: idToken.payload["family_name"] || "",
        phone_number: idToken.payload["phone_number"] || "",
        mfa_enabled:
          user.challengeName === "SOFTWARE_TOKEN_MFA"
            ? true
            : user.preferredMFA === "NOMFA"
            ? false
            : true,
      })
    );
    setUsername(idToken.payload.email || "");
    setIsAuthenticated(true);
    setNeedsAccountSetup(login);
  };

  useEffect(() => {
    dispatch(setLoading(true));
    Auth.currentAuthenticatedUser()
      .then((result) => {
        setUserContextData(result, true);
      })
      .catch(() => {
        setUsername("");
        setIsAuthenticated(false);
        dispatch(clearUserContext());
      })
      .finally(() => {
        dispatch(setLoading(false));
      });
  }, []);

  // Sign In user
  const signIn = async (username: string, password: string) => {
    dispatch(setLoading(true));

    await Auth.signIn(username, password)
      .then((result) => {
        //console.log("SIGN IN");

        if (result.challengeName === "SOFTWARE_TOKEN_MFA") {
          setUser(result);
          navigate("/auth/lock/default");
        } else {
          setUserContextData(result, true);
          notifySimple("Signed In", "success");
        }
      })
      .catch(async (err) => {
        switch (err.code) {
          case "UserNotConfirmedException":
            setUsername(username);
            await resendSignUp(username);
            navigate("/auth/verification/default");
            break;
          default:
            notifySimple(err.message, "error");
            break;
        }
      })
      .finally(() => {
        dispatch(setLoading(false));
      });
  };

  // TO-DO: MFA
  const confirmSignIn = async (code: string) => {
    dispatch(setLoading(true));

    await Auth.confirmSignIn(user, code, "SOFTWARE_TOKEN_MFA")
      .then((result) => {
        //console.log("AUTHHHHH");

        setUser(null);
        setUserContextData(result, true);
      })

      .catch((err) => {
        notifySimple(err.message, "error");
      })
      .finally(() => {
        dispatch(setLoading(false));
      });
  };

  // Sign up new user
  const signUp = async (data: SignUpFormType) => {
    dispatch(setLoading(true));

    await Auth.signUp({
      username: data.email,
      password: data.password,
      attributes: {
        name: data.name,
        family_name: data.family_name,
        email: data.email,
        phone_number: data.phone_number,
      },
      autoSignIn: {
        enabled: true,
      },
    })
      .then((result) => {
        //console.log("SIGN UP");
        setUsername(data.email);
        navigate("/auth/verification/default");
      })
      .catch((err) => {
        notifySimple(err.message, "error");
      })
      .finally(() => {
        dispatch(setLoading(false));
      });
  };

  // Confirm Sign Up with email OTP
  const confirmSignUp = async (code: string) => {
    dispatch(setLoading(true));
    await Auth.confirmSignUp(username, code)
      .then((result) => {
        //console.log("CONFIRM SIGN UP");
        navigate("/auth/sign-in/default");
      })
      .catch((err) => {
        notifySimple(err.message, "error");
      })
      .finally(() => {
        dispatch(setLoading(false));
      });
  };

  // User registered but not Authorized
  const resendSignUp = async (user: string) => {
    dispatch(setLoading(true));

    await Auth.resendSignUp(user || username)
      .then(() => {
        notifySimple("Verification Code send", "success");
      })
      .catch((err) => {
        notifySimple(err.message, "error");
      })
      .finally(() => {
        dispatch(setLoading(false));
      });
  };

  // Sign out
  const signOut = async () => {
    dispatch(setLoading(true));

    await Auth.signOut()
      .then(() => {
        //console.log("SIGN OUT");
        notifySimple("Sign out", "success");
        setUsername("");
        setIsAuthenticated(false);
        dispatch(clearUserContext());
        dispatch(clearAccountContext());
        localStorage.setItem("accountContext", "");
        localStorage.setItem("siteList", "");
        navigate("/auth/sign-in/default");
      })
      .catch((err) => {
        notifySimple(err.message, "error");
      })
      .finally(() => {
        dispatch(setLoading(false));
      });
  };

  // Send forgot password verification code
  const forgotPassword = async (user: string) => {
    dispatch(setLoading(true));

    await Auth.forgotPassword(user || username)
      .then((result) => {
        //console.log("FORGOT PASWWORD");
        setUsername(user || username);
        notifySimple("Verification code send", "success");
        navigate("/auth/confirm-forgot-password/default");
      })
      .catch((err) => {
        notifySimple(err.message, "error");
      })
      .finally(() => {
        dispatch(setLoading(false));
      });
  };

  // Submit forgot password - Create new
  const forgotPasswordSubmit = async (code: string, newPassword: string) => {
    dispatch(setLoading(true));

    await Auth.forgotPasswordSubmit(username, code, newPassword)
      .then((result) => {
        //console.log("FORGOT PASSWORD SUBMIT");
        notifySimple("New password created", "success");
        navigate("/auth/sign-in/default");
      })
      .catch((err) => {
        notifySimple(err.message, "error");
      })
      .finally(() => {
        dispatch(setLoading(false));
      });
  };

  // Update user attributes
  const updateUserAttributes = async (data: any): Promise<any> => {
    dispatch(setLoading(true));

    await Auth.currentAuthenticatedUser()
      .then((user) => {
        return Auth.updateUserAttributes(user, data)
          .then(() => {
            return Auth.currentAuthenticatedUser();
          })
          .then((updatedUser) => {
            setUserContextData(updatedUser, false);
            notifySimple("Account updated!", "success");
            return true;
          });
      })
      .catch((err) => {
        notifySimple(err.message, "error");
      })
      .finally(() => {
        dispatch(setLoading(false));
      });
  };

  // Change password while logged in
  const changePassword = async (data: ChangePasswordFormType) => {
    dispatch(setLoading(true));

    await Auth.currentAuthenticatedUser()
      .then((user) => {
        return Auth.changePassword(
          user,
          data.oldPassword,
          data.newPassword
        ).then(() => {
          notifySimple("Password changed!", "success");
        });
      })
      .catch((err) => {
        notifySimple(err.message, "error");
      })
      .finally(() => {
        dispatch(setLoading(false));
      });
  };

  // Delete account completely
  const deleteUser = async () => {
    dispatch(setLoading(true));

    setUsername("");
    setIsAuthenticated(false);
    dispatch(clearUserContext());
    dispatch(clearAccountContext());
    localStorage.setItem("accountContext", "");
    localStorage.setItem("siteList", "");
    navigate("/auth/sign-in/default");
    notifySimple("Account deleted!", "success");

    dispatch(setLoading(false));

    // await Auth.deleteUser()
    //   .then((result) => {
    //     setUsername("");
    //     setIsAuthenticated(false);
    //     dispatch(clearUserContext());
    //     dispatch(clearAccountContext());
    //     navigate("/auth/sign-in/default");
    //     notifySimple("Account deleted!", "success");
    //   })
    //   .catch((err) => {
    //     notifySimple(err.message, "error");
    //   })
    //   .finally(() => {
    //     dispatch(setLoading(false));
    //   });
  };

  const setupTOTP = async (): Promise<any> => {
    dispatch(setLoading(true));

    try {
      const user = await Auth.currentAuthenticatedUser();
      const secretCode = await Auth.setupTOTP(user);

      const authCode = `otpauth://totp/AWSCognito:${user.username}?secret=${secretCode}&issuer=Cognito`;

      dispatch(setLoading(false));

      return {
        success: true,
        qrCode: authCode,
        secretCode: secretCode,
      };
    } catch (err: any) {
      notifySimple(err.message, "error");
      dispatch(setLoading(false));

      return {
        success: false,
        qrCode: "",
        secretCode: "",
      };
    }
  };

  const verifyTotpToken = async (code: string) => {
    dispatch(setLoading(true));

    await Auth.currentAuthenticatedUser()
      .then((user) => {
        return Auth.verifyTotpToken(user, code).then((result) => {
          return Auth.setPreferredMFA(user, "TOTP").then((resul) => {
            notifySimple("MFA Enabled", "success");
            dispatch(setMfaEnabled(true));
          });
        });
      })
      .catch((err) => {
        notifySimple(err.message, "error");
      })
      .finally(() => {
        dispatch(setLoading(false));
      });
  };

  const disableTotp = async () => {
    dispatch(setLoading(true));

    await Auth.currentAuthenticatedUser()
      .then((user) => {
        return Auth.setPreferredMFA(user, "NOMFA").then((resul) => {
          notifySimple("MFA Disabled", "success");
          dispatch(setMfaEnabled(false));
        });
      })
      .catch((err) => {
        notifySimple(err.message, "error");
      })
      .finally(() => {
        dispatch(setLoading(false));
      });
  };

  return {
    isAuthenticated,
    needsAccountSetup,
    username,
    setNeedsAccountSetup,
    signIn,
    signOut,
    signUp,
    confirmSignUp,
    deleteUser,
    forgotPassword,
    forgotPasswordSubmit,
    changePassword,
    confirmSignIn,
    resendSignUp,
    updateUserAttributes,
    setupTOTP,
    verifyTotpToken,
    disableTotp,
  };
};
