import { Routes, Route, Navigate } from "react-router-dom";

import AdminLayout from "layouts/admin";
import AuthLayout from "layouts/auth";
import "assets/css/Plugins.css";
import { useState, useEffect } from "react";
import Toaster from "providers/toast";
import { useAuth } from "api/hooks/useAuth";
import PrivateRoute from "PrivateRoute";
import AccountsDefault from "views/auth/accounts/AccountsDefault";

interface ThemeApp {
  [key: string]: string;
}

const App = () => {
  const { isAuthenticated, needsAccountSetup } = useAuth();
  // Create a new context
  const [themeApp, setThemeApp] = useState<ThemeApp>({
    "--background-100": "#F5F6FA",
    "--background-900": "#191C1E",
    "--shadow-100": "rgba(112, 144, 176, 0.08)",
    "--color-50": "#1d4a64",
    "--color-100": "#606b89",
    "--color-200": "#e0ebf4",
    "--color-300": "#557b90",
    "--color-400": "#8286ad",
    "--color-500": "#006684",
    "--color-600": "#1b445a",
    "--color-700": "#a6a8c9",
    "--color-800": "#50cfe7",
    "--color-900": "#0d1e25",
  });
  const [mini, setMini] = useState(false);

  // When the theme state changes, this effect will update the CSS variables in the document's root element
  useEffect(() => {
    Object.entries(themeApp).forEach(([key, value]) => {
      document.documentElement.style.setProperty(key, value);
    });
  }, [themeApp]);

  return (
    <div>
      <Routes>
        {isAuthenticated ? (
          needsAccountSetup ? (
            <>
              <Route
                path="auth/accounts/default"
                element={<AccountsDefault />}
              />
              <Route
                path="*"
                element={<Navigate to="auth/accounts/default" replace />}
              />
            </>
          ) : (
            <>
              <Route
                path="admin/*"
                element={
                  <PrivateRoute>
                    <AdminLayout
                      setMini={setMini}
                      mini={mini}
                      theme={themeApp}
                      setTheme={setThemeApp}
                    />
                  </PrivateRoute>
                }
              />
              <Route
                path="/"
                element={
                  <PrivateRoute>
                    <Navigate to="/admin" replace />
                  </PrivateRoute>
                }
              />
            </>
          )
        ) : (
          <>
            {/* Route to AuthLayout if not authenticated */}
            <Route path="auth/*" element={<AuthLayout />} />
            <Route path="/" element={<Navigate to="/auth" replace />} />
          </>
        )}

        {/* Optional: Handle other routes or 404 */}
        <Route
          path="*"
          element={
            <Navigate to={isAuthenticated ? "/admin" : "/auth"} replace />
          }
        />
      </Routes>

      <Toaster />
    </div>
  );
};

export default App;
