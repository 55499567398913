import {
  MdDashboard,
  MdHome,
  MdLock,
  MdFileCopy,
  MdPerson,
  MdDevices,
} from "react-icons/md";

// Auth Imports
import ForgotPasswordDefault from "views/auth/forgotPassword/ForgotPasswordDefault";
import SignInDefault from "views/auth/signIn/SignInDefault";
import SignUpDefault from "views/auth/signUp/SignUpDefault";
import VerificationDefault from "views/auth/verification/VerificationDefault";
import SmartHome from "views/admin/dashboards/page";
import Devices from "views/admin/devices/page";
import Sites from "views/admin/sites/page";
import Reports from "views/admin/reports/page";
import Profile from "views/admin/profile/page";
import ConfirmForgotPasswordDefault from "views/auth/confirmForgotPassword/ConfirmForgotPasswordDefault";
import LockDefault from "views/auth/lock/LockDefault";
import Users from "views/admin/users/page";
import User from "views/admin/users/user/page";
import Widgets from "views/admin/widgets/page";
import Device from "views/admin/devices/device/page";
import Site from "views/admin/sites/site/page";
import Support from "views/admin/support/page";
import AccountsDefault from "views/auth/accounts/AccountsDefault";

const routes = [
  {
    name: "Dashboards",
    layout: "/admin",
    path: "/dashboards",
    icon: <MdDashboard className="text-inherit h-5 w-5" />,
    collapse: false,
    component: <SmartHome />,
    roles: ["super", "administrator", "owner", "manager", "user"],
  },
  {
    name: "Devices",
    layout: "/admin",
    path: "/devices",
    icon: <MdDevices className="text-inherit h-5 w-5" />,
    collapse: false,
    component: <Devices />,
    roles: ["super", "administrator", "owner", "manager", "user"],
  },
  {
    name: "Sites",
    layout: "/admin",
    path: "/sites",
    icon: <MdHome className="text-inherit h-5 w-5" />,
    collapse: false,
    component: <Sites />,
    roles: ["super", "administrator", "owner", "manager", "user"],
  },
  {
    name: "Reports",
    layout: "/admin",
    path: "/reports",
    icon: <MdFileCopy className="text-inherit h-5 w-5" />,
    collapse: false,
    component: <Reports />,
    roles: ["super", "administrator", "owner", "manager"],
  },
  {
    name: "Users",
    layout: "/admin",
    path: "/users",
    icon: <MdPerson className="text-inherit h-5 w-5" />,
    collapse: false,
    component: <Users />,
    roles: ["super", "administrator", "owner", "manager"],
  },
  {
    name: "Profile",
    layout: "/admin",
    path: "/profile",
    icon: <MdLock className="text-inherit h-5 w-5" />,
    collapse: false,
    component: <Profile />,
    roles: ["super", "administrator", "owner", "manager", "user"],
  },
  {
    name: "Support",
    layout: "/admin",
    path: "/support",
    icon: <MdLock className="text-inherit h-5 w-5" />,
    collapse: false,
    component: <Support />,
    roles: ["super", "administrator"],
  },
  {
    name: "Widgets",
    layout: "/admin",
    path: "/widgets",
    icon: <MdLock className="text-inherit h-5 w-5" />,
    collapse: false,
    component: <Widgets />,
    roles: ["super"],
  },
];

export const allroutes = [
  {
    name: "User",
    layout: "/admin",
    path: "/users/user",
    component: <User />,
    roles: ["super", "administrator", "owner", "manager"],
  },
  {
    name: "Device",
    layout: "/admin",
    path: "/devices/device",
    component: <Device />,
    navbar: "water",
    roles: ["super", "administrator", "owner", "manager", "user"],
  },
  {
    name: "Site",
    layout: "/admin",
    path: "/sites/site",
    component: <Site />,
    navbar: "water",
    roles: ["super", "administrator", "owner", "manager"],
  },
];

export const auth = [
  {
    name: "Sign In",
    layout: "/auth",
    path: "/sign-in/default",
    component: <SignInDefault />,
  },
  {
    name: "Sign Up",
    layout: "/auth",
    path: "/sign-up/default",
    component: <SignUpDefault />,
  },
  {
    name: "Verification",
    layout: "/auth",
    path: "/verification/default",
    component: <VerificationDefault />,
  },
  {
    name: "Forgot Password",
    layout: "/auth",
    path: "/forgot-password/default",
    component: <ForgotPasswordDefault />,
  },
  {
    name: "Confirm Forgot Password",
    layout: "/auth",
    path: "/confirm-forgot-password/default",
    component: <ConfirmForgotPasswordDefault />,
  },
  {
    name: "Lock",
    layout: "/auth",
    path: "/lock/default",
    component: <LockDefault />,
  },
  {
    name: "Accounts",
    layout: "/auth",
    path: "/accounts/default",
    component: <AccountsDefault />,
  },
];

export default routes;
