import React, { useEffect } from "react";
import { setLoading } from "store/appSlice";
import { reportUsageBasic } from "api/report";
import { useDispatch } from "react-redux";
import { ReportResponse } from "api/types/report";
import ReportViewer from "reportDesigner/viewer";

interface MyDocumentProps {
  body: any;
}

interface Step {
  start?: any;
  end?: any;
  rate?: any;
  type?: string;
}

const packageRatesTable = (input: { title: any; data: any; group: any }) => {
  const { title, data, group } = input;

  // Create a new structure
  const packagedData = {
    title: `${group}: ${title}`,
    data: [] as Step[],
  };

  // Check if the input has steps
  if (data.type === "steps" && Array.isArray(data.steps)) {
    packagedData.data = data.steps.map(
      (step: { stepStart: any; stepEnd: any; rate: any }, index: number) => ({
        steps: index + 1,
        start: step.stepStart,
        end: step.stepEnd ?? null, // Handle cases where stepEnd is missing
        rate: step.rate,
      })
    );
  }

  // Check if there is a fixedRate and include it
  if (data.type === "fixed") {
    packagedData.data.push({
      type: "fixed",
      rate: data.fixedRate,
    });
  }

  return packagedData;
};

type RateStep = {
  stepStart: number;
  stepEnd?: number;
  rate: number;
};

type BreakdownStep = {
  stepStart: number;
  stepEnd?: number;
  rate: number;
  usage: number;
  cost: number;
};

type Device = {
  snr: string;
  totalCost: number;
  breakdown: BreakdownStep[];
};

type Rates = {
  title: string;
  data: {
    type: string;
    fixedRate: number;
    steps: RateStep[];
  };
  group: string;
};

type BreakdownData = {
  title: string;
  data: Device[];
  group: string;
};

function unpackFlatBreakdown(rates: Rates, breakdownData: BreakdownData) {
  const rateSteps = rates.data.steps;

  // Define the type for a flat data entry
  type FlatDataEntry = {
    snr: string;
    totalUsage: number;
    totalCost: number;
    [key: string]: string | number; // For dynamic step fields
  };

  // Helper to format a single device
  function formatDevice(device: Device): FlatDataEntry {
    const formattedDevice: FlatDataEntry = {
      snr: device.snr,
      totalUsage: 0,
      totalCost: 0,
    };

    // Add each step usage and cost to the flat structure
    rateSteps.forEach((step, index) => {
      const stepKey = `step${index + 1}`;
      const matchingStep = device.breakdown.find(
        (bd) => bd.stepStart === step.stepStart && bd.stepEnd === step.stepEnd
      );

      // Add step usage and cost
      formattedDevice[`${stepKey}Usage`] = matchingStep
        ? matchingStep.usage
        : 0;
      formattedDevice[`${stepKey}Rate`] = step.rate;
      formattedDevice[`${stepKey}Cost`] = matchingStep ? matchingStep.cost : 0;

      // Update total usage and cost
      formattedDevice.totalUsage += matchingStep ? matchingStep.usage : 0;
      formattedDevice.totalCost += matchingStep ? matchingStep.cost : 0;
    });

    return formattedDevice;
  }

  // Process each device
  const flatData = breakdownData.data.map(formatDevice);

  return {
    title: `${breakdownData.group}: ${breakdownData.title}`,
    data: flatData,
  };
}

const UsageReport: React.FC<MyDocumentProps> = ({ body }) => {
  // Dynamically retrieve headers from the first item
  const [reportData, setReportData] = React.useState<ReportResponse>();

  const dispatch = useDispatch();
  const handleGenerateReport = async (formData: any) => {
    dispatch(setLoading(true));
    const { data, isLoading } = await reportUsageBasic(formData);
    dispatch(setLoading(isLoading));

    if (data) {
      setReportData(data);
    }
  };

  useEffect(() => {
    if (body) {
      handleGenerateReport(body);
    }
  }, [body]);

  return (
    <>
      <ReportViewer report={reportData} />
    </>
  );
};

export default UsageReport;
