import { updateSite } from "api/site";
import { Site, SiteUpdateBody } from "api/types/site";
import { t } from "i18next";
import { useState } from "react";
import { MdEditDocument } from "react-icons/md";
import { useDispatch } from "react-redux";
import { setLoading } from "store/appSlice";
import GenericCard from "widgets/cards/generic";
import SiteGeneralForm, {
  SiteGeneralFormType,
} from "widgets/forms/sites/general";

interface GeneralProps {
  site?: Site;
  onUpdate?: () => void;
}

const General: React.FC<GeneralProps> = ({ site, onUpdate }) => {
  const dispatch = useDispatch();
  const [edit, setEdit] = useState(false);

  const doUpdateSite = async (formData: SiteGeneralFormType) => {
    const body: SiteUpdateBody = {
      id: site?.id,
      name: formData?.name,
      reference: formData?.reference,
      siteArea: formData?.siteArea,
      siteId: formData?.siteId,
      reportEmail: formData?.reportEmail,
    };

    dispatch(setLoading(true));
    const { status, isLoading } = await updateSite(body);
    dispatch(setLoading(isLoading));

    if (status && status === 200) {
      setEdit(false);
      onUpdate();
    }
  };

  return (
    <GenericCard
      title={site?.name}
      // description={site?.siteId}
      bgColor="bg-brand-500"
      // extra="h-fit"
      extraTitle="text-secondary heading-md"
      topRight={
        <MdEditDocument
          className="heading-lg text-secondary hover:cursor-pointer"
          onClick={() => {
            setEdit(!edit);
          }}
        />
      }
    >
      <SiteGeneralForm
        values={{
          name: site?.name || "",
          reference: site?.reference || "",
          siteArea: site?.siteArea || "",
          siteId: site?.siteId || "",
          reportEmail: site?.reportEmail || "",
        }}
        disabled={!edit}
        submit={doUpdateSite}
        onClose={() => {}}
      />
    </GenericCard>
  );
};

export default General;
