// Imports
import { CellContext, createColumnHelper } from "@tanstack/react-table";
import Progress from "components/progress";
import { t } from "i18next";
import MoreAction, { DropDownItem } from "widgets/moreAction";

// Define the structure of the JdTableModel
export class ClassPageTableModel {
  id: string;
  name: string;
  reference: string;
  devCount: number;
  verifiedCount: number;
  actions: string;
}
const defineClTablecolumns = (
  onEditClick?: (info: CellContext<ClassPageTableModel, any>) => void,
  onRemoveClick?: (info: CellContext<ClassPageTableModel, any>) => void
) => {
  // Initialize the translation hook and
  const moreMenuItems: DropDownItem[] = [
    {
      label: "Edit",
      active: true,
      onSelected: onEditClick,
    },
    {
      label: "Remove",
      active: true,
      onSelected: onRemoveClick,
    },
  ];
  // create a column helper for the JdTableModel
  const columnHelper = createColumnHelper<ClassPageTableModel>();

  // Define the columns for the JdTable
  const classPageTablecolumns = [
    // Description column
    columnHelper.accessor("name", {
      enableSorting: true,
      header: () => <span className="">{t("table.header.site.name")}</span>,
      cell: (info) => <span className="">{info.getValue()}</span>,
      meta: { rowClick: true },
    }),

    // Status column
    columnHelper.accessor("devCount", {
      enableSorting: false,
      header: () => (
        <span className="">{t("table.header.site.device.count")}</span>
      ),
      cell: (info) => <span className="">{info.getValue()}</span>,
      meta: { rowClick: true },
    }),

    columnHelper.accessor("verifiedCount", {
      enableSorting: false,
      header: () => (
        <span className="">{t("table.header.site.verified.count")}</span>
      ),
      cell: (info) => (
        <span className="">
          {`${info.getValue()} / ${info.row.original.devCount}`}
          <Progress
            completedItems={info.row.original.verifiedCount}
            totalItems={info.row.original.devCount}
            // color="blue"
          />
        </span>
      ),
      meta: { rowClick: true },
    }),

    // Actions column
    columnHelper.accessor("actions", {
      enableSorting: false,
      meta: {
        rowClick: false,
        customClass: "justify-items-center",
        customHeadClass: "justify-items-center",
      },

      header: () => <span className="">{t("table.header.actions")}</span>,
      cell: (info) => (
        <div>
          <MoreAction
            items={moreMenuItems}
            info={info}
            title="Site Options"
            extra="w-full flex"
          />
        </div>
      ),
    }),
  ];

  return classPageTablecolumns;
};

export default defineClTablecolumns;
