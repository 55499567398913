// Define the enum for sections
export enum Section {
  USER_COUNT = "userCount",
  SITE_COUNT = "siteCount",
  DEVICE_COUNT = "deviceCount",
  DEVICE_LOCATIONS = "deviceLocations",
  DEVICE_MODEL_BREAKDOWN = "deviceModelBreakdown",
}

// Update the DashboardReqBody type to use the enum
export type DashboardReqBody = {
  sections: Section[];
};

export type DeviceLocation = {
  snr: string;
  location: string;
};

export type OverviewDashboardMetrics = {
  userCount: number;
  siteCount: number;
  deviceCount: number;
  deviceLocations: DeviceLocation[];
  deviceModelBreakdown: {};
};
