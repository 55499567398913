type Role = {
  value: string;
  label: string;
};

export const roles: Role[] = [
  { value: "super", label: "Super" },
  { value: "administrator", label: "Administrator" },
  { value: "owner", label: "Owner" },
  { value: "manager", label: "Manager" },
  { value: "user", label: "User" },
];

const roleHierarchy: { [key: string]: number } = {
  super: 1,
  administrator: 2,
  owner: 3,
  manager: 4,
  user: 5,
};

export const getAvailableRoles = (currentRole: string): Role[] => {
  return roles.filter(
    (role) => roleHierarchy[role.value] > roleHierarchy[currentRole]
  );
};

export const getAvailableRolesIncl = (currentRole: string): Role[] => {
  return roles.filter(
    (role) => roleHierarchy[role.value] >= roleHierarchy[currentRole]
  );
};

export const canNavigateByRole = (
  currentRole: string,
  userRole: string
): boolean => {
  return roleHierarchy[currentRole] < roleHierarchy[userRole];
};

export const canShowByRole = (
  currentRole: string,
  userRole: string
): boolean => {
  return roleHierarchy[currentRole] <= roleHierarchy[userRole];
};
