import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useSearchParams } from "react-router-dom";
import { setLoading } from "store/appSlice";

import { ViewPort } from "models/constants/styling";
import General from "./components/general";
import { getSite } from "api/site";
import { Site } from "api/types/site";
import Rates from "./components/rates";
import Configuration from "./components/configuration";

const Sites = () => {
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  // Extract the filter parameter
  const id = searchParams.get("id");

  const [site, setSite] = useState<Site>(null);

  const fetchData = async () => {
    dispatch(setLoading(true));
    const { data, status, isLoading } = await getSite(id);
    dispatch(setLoading(isLoading));
    if (status && status === 200) {
      setSite(data);
    }
  };

  useEffect(() => {
    fetchData();
  }, [id]);

  return (
    <div className={`${ViewPort.contentFitHeight}`}>
      <div className={`mt-3 grid h-full w-full grid-cols-1 gap-4 px-4`}>
        <div className="grid grid-cols-1 gap-4 lg:grid-cols-2">
          <General site={site} onUpdate={fetchData} />
          <Configuration site={site} onUpdate={fetchData} />
        </div>
        <div className="grid min-h-[440px] grid-cols-1">
          <Rates site={site} onUpdate={fetchData} />
        </div>
      </div>
    </div>
  );
};

export default Sites;
