// Props
type TableCardProps = {
  extra?: string;
  children?: React.ReactNode;
};

const TableCard = ({ extra, children }: TableCardProps) => {
  return (
    <div
      className={`!z-5 relative flex flex-col rounded-[20px] bg-white px-4 dark:!bg-navy-800 dark:text-white md:px-6 ${extra}`}
    >
      {children}
    </div>
  );
};

export default TableCard;
