import * as React from "react";
import { InputHTMLAttributes, forwardRef, useEffect, useState } from "react";
import {
  MdClear,
  MdOutlineArrowCircleDown,
  MdOutlineArrowCircleUp,
} from "react-icons/md";
import DropdownButton from "../buttons/dropdown";
import { Popover, PopoverContent, PopoverTrigger } from "@chakra-ui/popover";
import { useDisclosure } from "@chakra-ui/hooks";
import { SearchInput } from "./search";
import { t } from "i18next";
import { PortalManager } from "@chakra-ui/portal";

type ValueSet = {
  [key: string]: any;
};

interface InputFieldProps extends InputHTMLAttributes<HTMLInputElement> {
  id: string;
  value: any;
  onChange: (values: any) => void;
  debounce?: number;
  items: ValueSet[];
  placeholder: string;
  labelKey?: string;
  valueKey?: string;
  filter?: boolean;
  state?: "error" | "success"; // Specific states for styling
  onFocus?: () => void;
  onBlur?: () => void;
  variant?: "solid" | "outline" | "filter";
  placement?: "top" | "bottom";
  clearIcon?: boolean;
  sizes?: "lg" | "md" | "sm" | "xs";
  minWidth?: string; // Add minWidth prop
  color?: "primary" | "secondary";
  rounded?: string;
  loading?: boolean;
}

const ComboBox = forwardRef<HTMLInputElement, InputFieldProps>(
  (
    {
      value,
      id,
      debounce = 500,
      labelKey = "label",
      valueKey = "value",
      variant = "outline",
      placement = "bottom",
      sizes = "lg",
      clearIcon = true,
      filter = false,
      items,
      placeholder,
      disabled,
      readOnly,
      onChange,
      onFocus,
      onBlur,
      state,
      minWidth = "200px",
      color = "primary",
      rounded = "rounded-2xl",
      loading = false,
    },
    ref
  ) => {
    const { onOpen, onClose, isOpen } = useDisclosure();
    const [values, setValue] = React.useState<any>(undefined);

    const [filterTerm, setFilterTerm] = useState("");

    const filteredItems = items.filter((item) =>
      item[labelKey].toLowerCase().includes(filterTerm.toLowerCase())
    );

    useEffect(() => {
      setValue(value);
    }, [value]);

    useEffect(() => {
      const timeout = setTimeout(() => {
        onChange(values);
        setFilterTerm("");
        // onBlur?.();
      }, debounce);

      return () => clearTimeout(timeout);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [values]);

    const isDisabled = disabled || loading || readOnly;

    const colorClasses = {
      primary: {
        filter: `text-primary bg-gray-50`,
        solid: `text-primary bg-gray-50/10`,
        outline: `border border-brand-700 text-primary`,
      },
      secondary: {
        filter: `text-secondary bg-gray-50`,
        solid: `text-secondary bg-gray-50/10`,
        outline: `border border-secondary text-secondary`,
      },
    };

    const sizeClasses = {
      lg: "h-12 px-6 text-txt-lg",
      md: "h-10 px-4 text-txt-md",
      sm: "h-8 px-3 text-txt-sm",
      xs: "h-6 px-2 text-txt-xs",
    };

    const popSizeClasses = {
      lg: "h-8 px-3 text-txt-lg",
      md: "h-8 px-3 text-txt-md",
      sm: "h-8 px-3 text-txt-sm",
      xs: "h-6 px-2 text-txt-xs",
    };

    const stateClasses = {
      error: "outline-red-500 text-red-500 placeholder:text-red-500",
      success: "outline-green-500 text-green-500 placeholder:text-green-500",
    };

    return (
      <div className={`flex ${minWidth}`}>
        <PortalManager zIndex={5000}>
          <Popover
            isOpen={isOpen && !disabled && items.length > 0}
            onOpen={() => {
              onOpen();
            }}
            onClose={onClose}
            closeOnBlur={true}
            matchWidth
            placement={placement}
          >
            <PopoverTrigger>
              <button
                type="button"
                id={id}
                onFocus={onFocus}
                disabled={disabled || readOnly}
                className={`flex w-full items-center justify-center  ${rounded} ${
                  sizeClasses[sizes]
                } ${colorClasses[color][variant]} ${
                  state ? stateClasses[state] : "outline-none"
                } ${
                  isDisabled
                    ? "border-none bg-gray-50/50"
                    : "hover:opacity-50 focus:opacity-100"
                }`}
              >
                <div
                  className={`flex w-full items-center justify-between gap-2 sm:gap-4`}
                >
                  <div className="flex items-center truncate">
                    {values ? (
                      <span className={`truncate`}>
                        {
                          filteredItems.find(
                            (item: any) => item[valueKey] === values
                          )?.[labelKey]
                        }
                      </span>
                    ) : (
                      <span className="truncate text-gray-500">
                        {placeholder}
                      </span>
                    )}
                  </div>
                  <div className=" flex gap-2">
                    {values && clearIcon && (
                      <MdClear
                        onClick={(e) => {
                          e.stopPropagation();

                          setValue(undefined);
                          onChange(undefined);
                        }}
                        className="cursor-pointer"
                      />
                    )}
                    {isOpen ? (
                      <MdOutlineArrowCircleUp className="" />
                    ) : (
                      <MdOutlineArrowCircleDown className="" />
                    )}
                  </div>
                </div>
              </button>
            </PopoverTrigger>
            {items && items.length > 0 && (
              <PopoverContent
                zIndex={7500}
                bg="white"
                className="`w-max max-h-60 rounded-xl px-2 py-2"
              >
                <>
                  {filter && (
                    <div className="px-2 pb-2">
                      <SearchInput
                        onChange={(filters) => setFilterTerm(filters)}
                        type="text"
                        placeholder={t("generic.action.search") + "..."}
                        value={filterTerm}
                        sizes={sizes}
                      />
                    </div>
                  )}
                  <div className="overflow-y-scroll">
                    {filteredItems.map((item: any, index: any) => (
                      <DropdownButton
                        key={index}
                        text={item[labelKey]}
                        onClick={() => {
                          setValue(item[valueKey]);
                          onClose();
                        }}
                        extra={popSizeClasses[sizes]}
                      />
                    ))}
                  </div>
                </>
              </PopoverContent>
            )}
          </Popover>
        </PortalManager>
      </div>
    );
  }
);

export default ComboBox;
