import { useSearchParams } from "react-router-dom";
import Delete from "./components/Delete";
import Details from "./components/Details";
import Role from "./components/Role";
import Sites from "./components/Sites";
import { useCallback, useEffect, useState } from "react";
import { userEdit, userGet } from "api/user";
import { setLoading } from "store/appSlice";
import { useDispatch } from "react-redux";
import { UserDetails, UserLinkReqBody } from "api/types/user";
import { UserEditFormType } from "widgets/forms/users/edit";
import { UserSitesFormType } from "widgets/forms/users/sites";
import { roles } from "models/dropdowns/roles";
import { notifySimple } from "providers/toast";

const User = () => {
  const dispatch = useDispatch();
  const [user, setUser] = useState<UserDetails>({
    id: "",
    firstName: "",
    lastName: "",
    email: "",
    role: "",
    classes: [],
  });
  const [searchParams] = useSearchParams();
  // Extract the filter parameter
  const id = searchParams.get("id");

  const updatePage = () => {
    fetchData();
  };

  const fetchData = useCallback(async () => {
    dispatch(setLoading(true));
    const { data, error, isLoading, status } = await userGet(id);
    dispatch(setLoading(isLoading));

    if (status === 200) {
      setUser(data);
    }
  }, [id]);

  useEffect(() => {
    fetchData();
  }, [id]);

  const handleRoleSubmit = (formData: UserEditFormType) => {
    setUser((prevUser) => {
      const updatedUser = {
        ...prevUser,
        role: formData.role,
      };
      updateLinkedUser(updatedUser); // Pass the updated user
      return updatedUser;
    });
  };

  const handleSitesSubmit = (formData: UserSitesFormType) => {
    setUser((prevUser) => {
      const updatedUser = {
        ...prevUser,
        classes: formData.classes,
      };
      updateLinkedUser(updatedUser); // Pass the updated user
      return updatedUser;
    });
  };

  const updateLinkedUser = useCallback(async (updatedUser: UserDetails) => {
    const body: UserLinkReqBody = {
      email: updatedUser.email,
      role: updatedUser.role,
      classes: updatedUser.classes,
    };
    dispatch(setLoading(true));
    const { data, error, isLoading, status } = await userEdit(body);
    dispatch(setLoading(isLoading));

    if (status === 200) {
      notifySimple("Success", "success");
    }
  }, []);

  return (
    <div className="mt-0 grid w-full grid-cols-1 gap-y-4 md:mt-6 ">
      <div className="flex flex-col gap-4 lg:flex-row">
        <div className="flex-1 md:flex-grow-[4] lg:flex-grow-[3]">
          <Details
            firstName={user.firstName}
            lastName={user.lastName}
            email={user.email}
          />
        </div>

        <div className="flex-1 lg:flex-grow-[4]">
          <Role role={user.role} onSubmit={handleRoleSubmit} />
        </div>

        <div className="flex-1 lg:flex-grow-[2]">
          <Delete email={user.email} />
        </div>
      </div>

      <div className="flex flex-col gap-4">
        <div className="flex flex-col gap-4 md:flex-row">
          <Sites classes={user.classes} onSubmit={handleSitesSubmit} />
        </div>
      </div>
    </div>
  );
};

export default User;
