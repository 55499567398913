// import { Navigate } from "react-router-dom";
// import { useAuth } from "./api/hooks/useAuth";

// type Props = {
//   children?: React.ReactNode;
// };

// const PrivateRoute: React.FC<Props> = ({ children }) => {
//   const { isAuthenticated } = useAuth();

//   // Not signed in go to Sign in page
//   if (!isAuthenticated) {
//     return <Navigate to="/auth/sign-in/default" />;
//   }

//   return <>{children}</>;
// };

// export default PrivateRoute;
import { useAuth } from "api/hooks/useAuth";
import { Navigate } from "react-router-dom";

const PrivateRoute = ({ children }: { children: JSX.Element }) => {
  const isAuthenticated = useAuth(); // Replace with your auth hook/context
  return isAuthenticated ? children : <Navigate to="/auth" replace />;
};

export default PrivateRoute;
