import * as z from "zod";

import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";

import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "../../../../providers/form";
import InputField from "widgets/shared/inputs/inputField";
import { useTranslation } from "react-i18next";
import i18next from "i18next";
import GenericButton from "widgets/shared/buttons/generic";
import { useEffect } from "react";
// Schema
const LevelConfigurationSchema = z.object({
  lowLevelPercentage: z.any(),
});

export type LevelConfigurationFormType = z.infer<
  typeof LevelConfigurationSchema
>;

// Props
type LevelConfigurationFormProps = {
  submit: (data: LevelConfigurationFormType) => void;
  onClose: () => void;
  defaultValues?: LevelConfigurationFormType;
  values?: LevelConfigurationFormType;
  loading?: boolean;
  disabled?: boolean;
};

const LevelConfigurationForm = ({
  submit,
  onClose,
  defaultValues,
  values,
  loading,
  disabled,
}: LevelConfigurationFormProps) => {
  const form = useForm<LevelConfigurationFormType>({
    resolver: zodResolver(LevelConfigurationSchema),
    defaultValues: {
      lowLevelPercentage: 0,
    },
    values,
  });

  const { t } = useTranslation();
  const { errors, isDirty } = form.formState;

  const clearErrors = () => {
    form.clearErrors();
  };

  // Automatically submit the form if valid
  const onSubmit = async (formData: LevelConfigurationFormType) => {
    try {
      await submit(formData);
    } catch (error) {
      console.error("Error submitting form:", error);
    }
  };

  useEffect(() => {
    if (disabled && isDirty) {
      form.reset(values || defaultValues);
    }
  }, [disabled, isDirty, form, values, defaultValues]);

  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)} className=" space-y-2">
        <div className="grid grid-cols-1 gap-2 md:grid-cols-2 md:gap-5">
          <FormField
            control={form.control}
            name="lowLevelPercentage"
            rules={{ required: false }}
            render={({ field }) => (
              <FormItem>
                <FormLabel className="text-secondary">
                  {t("form.input.daily.limit", { context: "level" })}
                </FormLabel>
                <FormControl>
                  <InputField
                    sizes={"md"}
                    placeholder={t("form.placeholder.daily.limit", {
                      context: "level",
                    })}
                    id="low-level-percentage"
                    type="number"
                    state={errors.lowLevelPercentage ? "error" : undefined}
                    onFocus={clearErrors}
                    disabled={disabled}
                    color="secondary"
                    variant="solid"
                    {...field}
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
        </div>

        {isDirty && !disabled && (
          <div className="flex w-full flex-col pt-4">
            <div className="flex justify-end">
              <GenericButton
                text={t("button.action.save.changes")}
                disabled={!isDirty}
                loading={loading || false}
                type="submit"
                color="secondary"
              />
            </div>
          </div>
        )}
      </form>
    </Form>
  );
};

export default LevelConfigurationForm;
