import * as z from "zod";

import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";

import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "../../../providers/form";
import { useTranslation } from "react-i18next";
import i18next from "i18next";
import InputField from "widgets/shared/inputs/inputField";
import GenericButton from "widgets/shared/buttons/generic";

// Schema
const ChangePasswordSchema = z
  .object({
    oldPassword: z.string().min(1, {
      message: i18next.t("zod.error.password"),
    }),
    newPassword: z
      .string()
      .min(8, {
        message: i18next.t("zod.error.password.length"),
      })
      .refine(
        (newPassword) => {
          // Check if newPassword contains at least one number and one special character
          const hasNumber = /[0-9]/.test(newPassword);
          const hasSpecialCharacter =
            /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/.test(newPassword);
          return hasNumber && hasSpecialCharacter;
        },
        {
          message: i18next.t("zod.error.password.match"),
        }
      ),
    confirmPassword: z.string().min(1, {
      message: i18next.t("zod.error.password"),
    }),
  })
  .refine(
    (data) => {
      return data.confirmPassword === data.newPassword;
    },
    {
      message: i18next.t("zod.error.no.match.password"),
      path: ["confirmPassword"],
    }
  );

export type ChangePasswordFormType = z.infer<typeof ChangePasswordSchema>;

// Props
type ChangePasswordFormProps = {
  submit: (data: ChangePasswordFormType) => void;
  loading?: boolean;
  extra?: string;
};

const ChangePasswordForm = ({
  submit,
  loading,
  extra,
}: ChangePasswordFormProps) => {
  const form = useForm<ChangePasswordFormType>({
    resolver: zodResolver(ChangePasswordSchema),
    defaultValues: {
      oldPassword: "",
      newPassword: "",
      confirmPassword: "",
    },
  });

  const { t } = useTranslation();
  const { errors, isDirty } = form.formState;

  const onSubmit = async (formData: ChangePasswordFormType) => {
    try {
      await submit(formData);
    } catch (error) {
      console.error("Error submitting form:", error);
    }
  };

  const clearErrors = () => {
    form.clearErrors();
  };

  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-2">
        <FormField
          control={form.control}
          name="oldPassword"
          rules={{ required: true }}
          render={({ field }) => (
            <FormItem>
              <FormLabel>{t("form.input.current.password")}</FormLabel>
              <FormControl>
                <InputField
                  placeholder={t("form.placeholder.password")}
                  id="oldPassword"
                  type="password"
                  state={errors.oldPassword ? "error" : undefined}
                  onFocus={clearErrors}
                  {...field}
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="newPassword"
          rules={{ required: true }}
          render={({ field }) => (
            <FormItem>
              <FormLabel>{t("form.input.new.password")}</FormLabel>
              <FormControl>
                <InputField
                  placeholder={t("form.placeholder.password")}
                  id="newPassword"
                  type="password"
                  state={errors.newPassword ? "error" : undefined}
                  onFocus={clearErrors}
                  {...field}
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="confirmPassword"
          rules={{ required: true }}
          render={({ field }) => (
            <FormItem>
              <FormLabel>{t("form.input.confirm.password")}</FormLabel>
              <FormControl>
                <InputField
                  placeholder={t("form.placeholder.password")}
                  id="confirmPassword"
                  type="password"
                  state={errors.confirmPassword ? "error" : undefined}
                  onFocus={clearErrors}
                  {...field}
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <div className="flex w-full flex-col pt-2">
          <div className="flex justify-end">
            <GenericButton
              text={t("button.action.save.changes")}
              disabled={!isDirty}
              loading={loading || false}
              type="submit"
              size="md"
            />
          </div>
        </div>
      </form>
    </Form>
  );
};

export default ChangePasswordForm;
