import { UserRespData } from "api/types/user";
import { userPage } from "api/user";
import { t } from "i18next";
import { ViewPort } from "models/constants/styling";
import {
  canNavigateByRole,
  getAvailableRoles,
  roles,
} from "models/dropdowns/roles";
import defineUserTablecolumns, { UserTableModel } from "models/tables/userPage";
import { notifySimple } from "providers/toast";
import { useCallback, useEffect, useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { getRole } from "store/account/accountSelectors";
import { accountSiteList } from "store/appSelectors";
import { setLoading } from "store/appSlice";
import TableCard from "widgets/cards/table";
import AddUserDialog from "widgets/dialogs/addUser";
import GenericButton from "widgets/shared/buttons/generic";
import Combobox from "widgets/shared/inputs/combobox";
import { SearchInput } from "widgets/shared/inputs/search";
import Table from "widgets/shared/tables/genericTable";

const Users = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const columns = defineUserTablecolumns(
    (info) => {
      const row = info.row.original;
      handleRowClick(row);
    },
    (info) => {
      const row = info.row.original;
    }
  );
  const userInfo = useSelector(accountSiteList, shallowEqual);
  const role = useSelector(getRole, shallowEqual);

  const [dataSource, setDataSource] = useState<UserRespData[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [dialog, setDialog] = useState(false);
  const [filter, setFilter] = useState("");
  const [state, setState] = useState({
    paginationState: { pageIndex: 0, pageSize: 12 },
    sortingState: [{ desc: true, id: "name" }],
    totCount: 0,
    pageCount: 0,
    classes: "",
    roles: "",
  });

  const handleRowClick = (row: UserTableModel) => {
    if (canNavigateByRole(role, row.role)) {
      // User has higher role, proceed to navigate
      navigate(`/admin/users/user?id=${row.id}`);
    } else {
      // Handle restricted access
      notifySimple("You do not have permission to view this user", "warning");
      console.log("You do not have permission to view this user's details.");
      // Optionally show a user-friendly message or notification
    }
  };

  const updatePage = () => {
    setDialog(false);
    fetchData();
  };

  const fetchData = useCallback(async () => {
    const { paginationState, sortingState, classes, roles } = state;
    setIsLoading(true);
    const { data, error, isLoading } = await userPage({
      search: filter,
      pageIndex: paginationState.pageIndex + 1,
      pageSize: paginationState.pageSize,
      desc: sortingState[0].desc,
      class: classes,
      roles: roles,
    });
    setIsLoading(isLoading);
    if (!error) {
      setDataSource(data.users || []);
      setState((prevState) => ({
        ...prevState,
        paginationState: { pageIndex: data.pageIndex - 1, pageSize: 10 },
        totCount: data.totalCount,
        pageCount: data.totalPages,
      }));
    }
  }, [
    filter,
    state.paginationState.pageIndex,
    state.paginationState.pageSize,
    state.sortingState,
    state.classes,
    state.roles,
  ]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return (
    <div className="mt-0 grid h-full w-full grid-cols-1 gap-y-4 md:mt-6">
      {role !== "user" && (
        <AddUserDialog
          isOpen={dialog}
          onClose={() => setDialog(false)}
          onSuccess={updatePage}
        />
      )}
      <TableCard extra="h-fit md:h-contentFitHeight">
        <Table
          filterbar={
            <div className="flex h-full w-full flex-col gap-y-3">
              <div className="flex justify-end">
                <GenericButton
                  onClick={() => {
                    setDialog(true);
                  }}
                  type="button"
                  text="Add User"
                  size="md"
                  rounded="rounded-xl"
                />
              </div>
              <div className="grid grid-cols-1 gap-2 md:grid-cols-4">
                <div className="col-span-1">
                  <Combobox
                    id="user-select-role"
                    placeholder="select role"
                    variant="filter"
                    value={""}
                    onChange={(item) => {
                      setState((prevState) => ({
                        ...prevState,
                        roles: item,
                      }));
                    }}
                    items={roles}
                    sizes="md"
                    rounded="rounded-xl"
                  />
                </div>
                <div className="col-span-1">
                  <Combobox
                    id="user-select-site"
                    placeholder="select site"
                    variant="filter"
                    filter
                    value={""}
                    onChange={(item) => {
                      setState((prevState) => ({
                        ...prevState,
                        classes: item,
                      }));
                    }}
                    items={userInfo}
                    labelKey="name"
                    valueKey="id"
                    sizes="md"
                    rounded="rounded-xl"
                  />
                </div>
                <div className="col-span-1 md:col-span-2">
                  <SearchInput
                    onChange={(filters) => {
                      setFilter(filters);
                      setState((prevState) => ({
                        ...prevState,
                        paginationState: { pageIndex: 0, pageSize: 10 },
                      }));
                    }}
                    type="text"
                    value={filter}
                    sizes="md"
                    rounded="rounded-xl"
                  />
                </div>
              </div>
            </div>
          }
          loading={isLoading}
          data={dataSource}
          columns={columns}
          pagination={state.paginationState}
          paginationOptions={{
            onPaginationChange: (pagination) => {
              const page =
                typeof pagination === "function"
                  ? pagination(state.paginationState)
                  : pagination;
              setState((prevState) => ({
                ...prevState,
                paginationState: page,
              }));
            },
            rowCount: state.totCount,
            pageCount: state.pageCount,
          }}
          sorting={state.sortingState}
          onSortingChange={(updaterOrValue) => {
            const newSortingState =
              typeof updaterOrValue === "function"
                ? updaterOrValue(state.sortingState)
                : updaterOrValue;
            if (newSortingState && newSortingState.length > 0) {
              setState((prevState) => ({
                ...prevState,
                sortingState: newSortingState,
              }));
            } else {
              setState((prevState) => ({
                ...prevState,
                sortingState: [{ id: "name", desc: true }],
              }));
            }
          }}
          onRowClick={handleRowClick}
        />
      </TableCard>
    </div>
  );
};

export default Users;
