import { RawReqBody, UsageReqBody } from "api/types/report";
import { t } from "i18next";
import { useEffect, useState } from "react";
import RawReport from "reportDesigner/types/raw";
import UsageReport from "reportDesigner/types/usage";
import { formatDateToZ } from "util/datelib";
import GenericCard from "widgets/cards/generic";

interface MyDocumentProps {
  data: any;
}

const Viewer: React.FC<MyDocumentProps> = ({ data }) => {
  const [body, setBody] = useState<any>(data);

  const handleBody = () => {
    console.log("Data", data);
    if (data.reportType === 1) {
      const rawBody: RawReqBody = {
        siteId: data.site,
        snr: data.device,
        from: formatDateToZ(data.dateRange[0]),
        to: formatDateToZ(data.dateRange[1], true),
      };

      setBody(rawBody);
    }
    if (data.reportType === 2) {
      const rawBody: UsageReqBody = {
        siteId: data.site,
        snrs: data.devices,
        from: formatDateToZ(data.dateRange[0]),
        to: formatDateToZ(data.dateRange[1], true),
        type: "water",
      };
      setBody(rawBody);
    }
    if (data.reportType === 3) {
      const rawBody: UsageReqBody = {
        siteId: data.site,
        snrs: data.devices,
        from: formatDateToZ(data.dateRange[0]),
        to: formatDateToZ(data.dateRange[1], true),
        type: "electricity",
      };
      setBody(rawBody);
    }
  };

  useEffect(() => {
    if (data) {
      handleBody();
    }
  }, [data]);

  return (
    <GenericCard bgColor="bg-white" extra="h-full">
      {data && (
        <>
          {data.reportType === 1 && <RawReport body={body} />}
          {(data.reportType === 2 || data.reportType === 3) && (
            <UsageReport body={body} />
          )}
        </>
      )}
    </GenericCard>
  );
};

export default Viewer;
