import LabeledField from "providers/labeledField";
import ProfileCard from "widgets/cards/profile";
import InputField from "widgets/shared/inputs/inputField";

const Details = ({ firstName = "", lastName = "", email = "" }) => {
  return (
    <ProfileCard title="User Details" bgColor="bg-brand-200" extra="h-full">
      <div className="mb-2 grid grid-cols-1 gap-2 md:mb-4 md:grid-cols-2 md:gap-5">
        <LabeledField label={"Name"} id={"pin"}>
          <InputField
            id={"1"}
            value={firstName}
            sizes="md"
            disabled
            color="primary"
            variant="solid"
          />
        </LabeledField>
        <LabeledField label={"Surname"} id={"pin"}>
          <InputField
            id={"1"}
            value={lastName}
            sizes="md"
            disabled
            color="primary"
            variant="solid"
          />
        </LabeledField>
      </div>
      <LabeledField label={"Email"} id={"pin"}>
        <InputField
          id={"1"}
          value={email}
          sizes="md"
          color="primary"
          variant="solid"
          disabled
        />
      </LabeledField>
    </ProfileCard>
  );
};

export default Details;
