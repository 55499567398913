import { t } from "i18next";
import { MenuRoutePaths } from "models/constants/routes";
import { BsFiletypePdf } from "react-icons/bs";

import { useNavigate } from "react-router-dom";
import DashboardCard from "widgets/cards/dashboard";
import CircleButton from "widgets/shared/buttons/circle-button";

const Reports = () => {
  const navigate = useNavigate();

  const handleRoute = () => {
    navigate(`/admin${MenuRoutePaths.REPORTS}`);
  };

  const reportTemplates = [
    { type: "raw", icon: BsFiletypePdf },
    { type: "water", icon: BsFiletypePdf },
    { type: "electricity", icon: BsFiletypePdf },
    { type: "temperature", icon: BsFiletypePdf },
  ];

  return (
    <DashboardCard
      title={t("dashboard.card.reports.title")}
      bgColor="bg-white"
      textColor="text-primary"
      extra="h-full"
    >
      <div className="mb-10 mt-6 flex flex-wrap justify-center gap-4 md:gap-12 xl:mt-12">
        {reportTemplates.map((template, index) => (
          <CircleButton
            icon={template.icon}
            onClick={handleRoute}
            variant="ghost"
          />
        ))}
      </div>
      <div className="flex items-center justify-center text-primary">
        Doubleshift to make placeholder image
      </div>
    </DashboardCard>
  );
};

export default Reports;
