import ProfileCard from "widgets/cards/profile";
import ComboBox from "widgets/shared/inputs/combobox";
import InputField from "widgets/shared/inputs/inputField";
import MultiCombobox from "widgets/shared/inputs/multiselectCombobox";
import PhoneNumber from "widgets/shared/inputs/phoneNumber";
import PinInputs from "widgets/shared/inputs/pinInput";
import { SearchInput } from "widgets/shared/inputs/search";
import ExampleForm from "./components/form";
import GenericButton from "widgets/shared/buttons/generic";
import { notifySimple } from "providers/toast";
import LabeledField from "providers/labeledField";
import GenericCard from "widgets/cards/generic";
import CircleButton from "widgets/shared/buttons/circle-button";
import { MdAdd } from "react-icons/md";
import { StatusDropdown } from "models/dropdowns/status";
import DateRangePickerField from "widgets/shared/inputs/dateRangePicker";
import Checkbox from "widgets/shared/inputs/checkbox";
import Switch from "widgets/shared/inputs/switch";
import DatePickerField from "widgets/shared/inputs/datePicker";
import Radio from "widgets/shared/inputs/radio";
import { useState } from "react";

const Widgets = () => {
  const [inputField, setInputField] = useState("");
  return (
    <div className="mt-6 grid h-full w-full grid-cols-1 gap-y-4 lg:grid-cols-2 lg:gap-x-4 lg:gap-y-4">
      <GenericCard
        title="Inputs"
        description="Example inputs"
        bgColor="bg-white"
      >
        <LabeledField
          label={"InputField"}
          id={"pin"}
          className="heading-md text-primary"
        >
          <div className="flex gap-1">
            <InputField
              id={"1"}
              placeholder="large..."
              sizes="lg"
              onChange={(e) => {
                setInputField(e);
              }}
              value={inputField}
            />
            <InputField
              id={"1"}
              placeholder="medium..."
              sizes="md"
              onChange={(e) => {
                setInputField(e);
              }}
              value={inputField}
            />
            <InputField
              id={"1"}
              placeholder="small..."
              sizes="sm"
              onChange={(e) => {
                setInputField(e);
              }}
              value={inputField}
            />
            <InputField
              id={"1"}
              placeholder="extra small..."
              sizes="xs"
              onChange={(e) => {
                setInputField(e);
              }}
              value={inputField}
            />
          </div>
          <div className="z-20 flex gap-1">
            <ComboBox
              id={"1"}
              placeholder="large..."
              sizes="lg"
              value={undefined}
              onChange={() => {}}
              items={StatusDropdown}
              minWidth="w-full"
            />
            <ComboBox
              id={"1"}
              placeholder="medium..."
              sizes="md"
              value={undefined}
              onChange={() => {}}
              items={StatusDropdown}
              minWidth="w-full"
            />
            <ComboBox
              id={"1"}
              placeholder="small..."
              sizes="sm"
              value={undefined}
              onChange={() => {}}
              items={StatusDropdown}
              minWidth="w-full"
            />
            <ComboBox
              id={"1"}
              placeholder="extra small..."
              sizes="xs"
              value={undefined}
              onChange={() => {}}
              items={StatusDropdown}
              minWidth="w-full"
            />
          </div>
          <div className="flex gap-1">
            <MultiCombobox
              id={"1"}
              placeholder="large..."
              sizes="lg"
              value={undefined}
              onChange={() => {}}
              items={StatusDropdown}
              minWidth="w-full"
              filter
              selectAll
            />
            <MultiCombobox
              id={"1"}
              placeholder="medium..."
              sizes="md"
              value={undefined}
              onChange={() => {}}
              items={StatusDropdown}
              minWidth="w-full"
              filter
              selectAll
            />
            <MultiCombobox
              id={"1"}
              placeholder="small..."
              sizes="sm"
              value={undefined}
              onChange={() => {}}
              items={StatusDropdown}
              minWidth="w-full"
            />
            <MultiCombobox
              id={"1"}
              placeholder="extra small..."
              sizes="xs"
              value={undefined}
              onChange={() => {}}
              items={StatusDropdown}
              minWidth="w-full"
            />
          </div>
          <div className="flex gap-1">
            <DateRangePickerField id={""} sizes="lg" />
            <DateRangePickerField id={""} sizes="md" />
            <DateRangePickerField id={""} sizes="sm" />
            <DateRangePickerField id={""} sizes="xs" />
          </div>
          <div className="flex gap-1">
            <DateRangePickerField id={""} sizes="lg" iconOnly />
            <DateRangePickerField id={""} sizes="md" iconOnly />
            <DateRangePickerField id={""} sizes="sm" iconOnly />
            <DateRangePickerField id={""} sizes="xs" iconOnly />
          </div>
          <div className="flex gap-1">
            <DatePickerField id={""} sizes="lg" />
            <DatePickerField id={""} sizes="md" />
            <DatePickerField id={""} sizes="sm" />
            <DatePickerField id={""} sizes="xs" />
          </div>
          <div className="flex gap-1">
            <DatePickerField id={""} sizes="lg" iconOnly />
            <DatePickerField id={""} sizes="md" iconOnly />
            <DatePickerField id={""} sizes="sm" iconOnly />
            <DatePickerField id={""} sizes="xs" iconOnly />
          </div>
          <div className="flex gap-1">
            <SearchInput value={undefined} onChange={() => {}} sizes="lg" />
            <SearchInput value={undefined} onChange={() => {}} sizes="md" />
            <SearchInput value={undefined} onChange={() => {}} sizes="sm" />
            <SearchInput value={undefined} onChange={() => {}} sizes="xs" />
          </div>
          <div className="flex gap-1">
            <Checkbox sizes="xl" onChange={() => {}} labelKey="hello" />
            <Checkbox sizes="lg" onChange={() => {}} labelKey="hello" />
            <Checkbox sizes="md" onChange={() => {}} labelKey="hello" />
          </div>
          <div className="flex gap-1">
            <Switch sizes="lg" onChange={() => {}} labelKey="hello" />
            <Switch sizes="md" onChange={() => {}} labelKey="hello" />
          </div>
          <div className="flex gap-1">
            <Radio onChange={() => {}} sizes="xl" labelKey="hello" />
            <Radio onChange={() => {}} sizes="lg" labelKey="hello" />
            <Radio onChange={() => {}} sizes="md" labelKey="hello" />
          </div>
          <div className="flex gap-1">
            <PinInputs value={undefined} onChange={undefined} />
          </div>
          <div className="flex gap-1">
            <PhoneNumber id={""} value={""} onChang={() => {}} />
          </div>
        </LabeledField>
      </GenericCard>
      <ProfileCard title="Form" description="Example">
        <ExampleForm
          submit={(value) => {
            console.log(value);
          }}
        />
      </ProfileCard>
      <GenericCard
        title="Buttons"
        description="Example buttons"
        bgColor="bg-white"
      >
        <LabeledField
          label={"Primary Buttons - Standard:"}
          id={"primary-buttons"}
          className="heading-md text-primary"
        >
          <div className="grid grid-cols-4 gap-5">
            <GenericButton
              variant={"solid"}
              size="xs"
              text={"Solid - xs"}
              onClick={() => {
                notifySimple("Success", "info");
              }}
            />
            <GenericButton
              variant={"solid"}
              size="sm"
              text={"Solid - sm"}
              onClick={() => {
                notifySimple("Warning", "warning");
              }}
            />
            <GenericButton
              variant={"solid"}
              size="md"
              text={"Solid - md"}
              onClick={() => {
                notifySimple("Error", "error");
              }}
            />
            <GenericButton
              variant={"solid"}
              size="lg"
              text={"Solid - lg"}
              onClick={() => {
                notifySimple("Success", "success");
              }}
            />
          </div>
          <div className="grid grid-cols-4 gap-5">
            <GenericButton
              variant={"outline"}
              size="xs"
              text={"Outline - xs"}
              onClick={() => {
                notifySimple("Success", "info");
              }}
            />
            <GenericButton
              variant={"outline"}
              size="sm"
              text={"Outline - sm"}
              onClick={() => {
                notifySimple("Warning", "warning");
              }}
            />
            <GenericButton
              variant={"outline"}
              size="md"
              text={"Outline - md"}
              onClick={() => {
                notifySimple("Error", "error");
              }}
            />
            <GenericButton
              variant={"outline"}
              size="lg"
              text={"Outline - lg"}
              onClick={() => {
                notifySimple("Success", "success");
              }}
            />
          </div>
          <div className="grid grid-cols-4 gap-5">
            <GenericButton
              variant={"ghost"}
              size="xs"
              text={"Ghost - xs"}
              onClick={() => {
                notifySimple("Success", "info");
              }}
            />
            <GenericButton
              variant={"ghost"}
              size="sm"
              text={"Ghost - sm"}
              onClick={() => {
                notifySimple("Warning", "warning");
              }}
            />
            <GenericButton
              variant={"ghost"}
              size="md"
              text={"Ghost - md"}
              onClick={() => {
                notifySimple("Error", "error");
              }}
            />
            <GenericButton
              variant={"ghost"}
              size="lg"
              text={"Ghost - lg"}
              onClick={() => {
                notifySimple("Success", "success");
              }}
            />
          </div>
          <div className="grid grid-cols-4 gap-5">
            <GenericButton
              variant={"link"}
              size="xs"
              text={"Link - xs"}
              onClick={() => {
                notifySimple("Success", "info");
              }}
            />
            <GenericButton
              variant={"link"}
              size="sm"
              text={"Link - sm"}
              onClick={() => {
                notifySimple("Warning", "warning");
              }}
            />
            <GenericButton
              variant={"link"}
              size="md"
              text={"Link - md"}
              onClick={() => {
                notifySimple("Error", "error");
              }}
            />
            <GenericButton
              variant={"link"}
              size="lg"
              text={"Link - lg"}
              onClick={() => {
                notifySimple("Success", "success");
              }}
            />
          </div>
        </LabeledField>
        <LabeledField
          label={"Secondary Buttons - Standard:"}
          id={"primary-buttons"}
          className="heading-md text-primary"
        >
          <div className="grid grid-cols-4 gap-5">
            <GenericButton
              variant={"solid"}
              size="xs"
              text={"Solid - xs"}
              onClick={() => {
                notifySimple("Success", "info");
              }}
              color="secondary"
            />
            <GenericButton
              variant={"solid"}
              size="sm"
              text={"Solid - sm"}
              onClick={() => {
                notifySimple("Warning", "warning");
              }}
              color="secondary"
            />
            <GenericButton
              variant={"solid"}
              size="md"
              text={"Solid - md"}
              onClick={() => {
                notifySimple("Error", "error");
              }}
              color="secondary"
            />
            <GenericButton
              variant={"solid"}
              size="lg"
              text={"Solid - lg"}
              onClick={() => {
                notifySimple("Success", "success");
              }}
              color="secondary"
            />
          </div>
          <div className="grid grid-cols-4 gap-5">
            <GenericButton
              variant={"outline"}
              size="xs"
              text={"Outline - xs"}
              onClick={() => {
                notifySimple("Success", "info");
              }}
              color="secondary"
            />
            <GenericButton
              variant={"outline"}
              size="sm"
              text={"Outline - sm"}
              onClick={() => {
                notifySimple("Warning", "warning");
              }}
              color="secondary"
            />
            <GenericButton
              variant={"outline"}
              size="md"
              text={"Outline - md"}
              onClick={() => {
                notifySimple("Error", "error");
              }}
              color="secondary"
            />
            <GenericButton
              variant={"outline"}
              size="lg"
              text={"Outline - lg"}
              onClick={() => {
                notifySimple("Success", "success");
              }}
              color="secondary"
            />
          </div>
          <div className="grid grid-cols-4 gap-5">
            <GenericButton
              variant={"ghost"}
              size="xs"
              text={"Ghost - xs"}
              onClick={() => {
                notifySimple("Success", "info");
              }}
              color="secondary"
            />
            <GenericButton
              variant={"ghost"}
              size="sm"
              text={"Ghost - sm"}
              onClick={() => {
                notifySimple("Warning", "warning");
              }}
              color="secondary"
            />
            <GenericButton
              variant={"ghost"}
              size="md"
              text={"Ghost - md"}
              onClick={() => {
                notifySimple("Error", "error");
              }}
              color="secondary"
            />
            <GenericButton
              variant={"ghost"}
              size="lg"
              text={"Ghost - lg"}
              onClick={() => {
                notifySimple("Success", "success");
              }}
              color="secondary"
            />
          </div>
          <div className="grid grid-cols-4 gap-5">
            <GenericButton
              variant={"link"}
              size="xs"
              text={"Link - xs"}
              onClick={() => {
                notifySimple("Success", "info");
              }}
              color="secondary"
            />
            <GenericButton
              variant={"link"}
              size="sm"
              text={"Link - sm"}
              onClick={() => {
                notifySimple("Warning", "warning");
              }}
              color="secondary"
            />
            <GenericButton
              variant={"link"}
              size="md"
              text={"Link - md"}
              onClick={() => {
                notifySimple("Error", "error");
              }}
              color="secondary"
            />
            <GenericButton
              variant={"link"}
              size="lg"
              text={"Link - lg"}
              onClick={() => {
                notifySimple("Success", "success");
              }}
              color="secondary"
            />
          </div>
        </LabeledField>
        <LabeledField
          label={"Buttons - Custom Settings:"}
          id={"primary-buttons"}
          className="heading-md text-primary"
        >
          <div className="flex gap-2">
            <GenericButton
              text={"Rounded-md"}
              rounded="rounded-md"
              onClick={() => {
                notifySimple("Success", "success");
              }}
            />
            <GenericButton
              text={"Disabled"}
              onClick={() => {
                notifySimple("Success", "success");
              }}
              disabled
            />
            <GenericButton
              text={"Loading"}
              onClick={() => {
                notifySimple("Success", "success");
              }}
              loading
            />
          </div>

          <GenericButton
            text={"Full width"}
            onClick={() => {
              notifySimple("Success", "success");
            }}
            extra="w-full"
          />
        </LabeledField>
        <LabeledField
          label={"Circle Buttons - Icons Only:"}
          id={"primary-buttons"}
          className="heading-md text-primary"
        >
          <div className="flex gap-2">
            <CircleButton icon={MdAdd} size="xs" />
            <CircleButton icon={MdAdd} size="sm" />
            <CircleButton icon={MdAdd} size="md" />
            <CircleButton icon={MdAdd} size="lg" />
          </div>
          <div className="flex gap-2">
            <CircleButton icon={MdAdd} size="xs" variant="outline" />
            <CircleButton icon={MdAdd} size="sm" variant="outline" />
            <CircleButton icon={MdAdd} size="md" variant="outline" />
            <CircleButton icon={MdAdd} size="lg" variant="outline" />
          </div>
          <div className="flex gap-2">
            <CircleButton icon={MdAdd} size="xs" variant="ghost" />
            <CircleButton icon={MdAdd} size="sm" variant="ghost" />
            <CircleButton icon={MdAdd} size="md" variant="ghost" />
            <CircleButton icon={MdAdd} size="lg" variant="ghost" />
          </div>
          <div className="flex gap-2">
            <CircleButton icon={MdAdd} size="xs" label="New" />
            <CircleButton icon={MdAdd} size="sm" label="New" />
            <CircleButton icon={MdAdd} size="md" label="New" />
            <CircleButton icon={MdAdd} size="lg" label="New" />
          </div>
          <div className="flex gap-2">
            <CircleButton
              icon={MdAdd}
              size="xs"
              variant="outline"
              label="New"
            />
            <CircleButton
              icon={MdAdd}
              size="sm"
              variant="outline"
              label="New"
            />
            <CircleButton
              icon={MdAdd}
              size="md"
              variant="outline"
              label="New"
            />
            <CircleButton
              icon={MdAdd}
              size="lg"
              variant="outline"
              label="New"
            />
          </div>
          <div className="flex gap-2">
            <CircleButton icon={MdAdd} size="xs" variant="ghost" label="New" />
            <CircleButton icon={MdAdd} size="sm" variant="ghost" label="New" />
            <CircleButton icon={MdAdd} size="md" variant="ghost" label="New" />
            <CircleButton icon={MdAdd} size="lg" variant="ghost" label="New" />
          </div>
          <div className="flex gap-2">
            <CircleButton
              icon={MdAdd}
              size="xs"
              label="New"
              iconPosition="right"
            />
            <CircleButton
              icon={MdAdd}
              size="sm"
              label="New"
              iconPosition="right"
            />
            <CircleButton
              icon={MdAdd}
              size="md"
              label="New"
              iconPosition="right"
            />
            <CircleButton
              icon={MdAdd}
              size="lg"
              label="New"
              iconPosition="right"
            />
          </div>
          <div className="flex gap-2">
            <CircleButton
              icon={MdAdd}
              size="xs"
              variant="outline"
              label="New"
              iconPosition="right"
            />
            <CircleButton
              icon={MdAdd}
              size="sm"
              variant="outline"
              label="New"
              iconPosition="right"
            />
            <CircleButton
              icon={MdAdd}
              size="md"
              variant="outline"
              label="New"
              iconPosition="right"
            />
            <CircleButton
              icon={MdAdd}
              size="lg"
              variant="outline"
              label="New"
              iconPosition="right"
            />
          </div>
          <div className="flex gap-2">
            <CircleButton
              icon={MdAdd}
              size="xs"
              variant="ghost"
              label="New"
              iconPosition="right"
            />
            <CircleButton
              icon={MdAdd}
              size="sm"
              variant="ghost"
              label="New"
              iconPosition="right"
            />
            <CircleButton
              icon={MdAdd}
              size="md"
              variant="ghost"
              label="New"
              iconPosition="right"
            />
            <CircleButton
              icon={MdAdd}
              size="lg"
              variant="ghost"
              label="New"
              iconPosition="right"
            />
          </div>
        </LabeledField>
      </GenericCard>
      <ProfileCard title="Text" description="Typography">
        <>To Do</>
      </ProfileCard>
      <ProfileCard title="Dialogs" description="Example">
        <>To Do</>
      </ProfileCard>
      <ProfileCard title="Toaster" description="Examples">
        <div className="mt-4 flex gap-5">
          <GenericButton
            variant={"solid"}
            size="lg"
            text={"Success"}
            onClick={() => {
              notifySimple("Success", "success");
            }}
            color="secondary"
          />
          <GenericButton
            variant={"solid"}
            size="md"
            text={"Error"}
            onClick={() => {
              notifySimple("Error", "error");
            }}
            color="secondary"
          />
          <GenericButton
            variant={"solid"}
            size="sm"
            text={"Warning"}
            onClick={() => {
              notifySimple("Warning", "warning");
            }}
            color="secondary"
          />
          <GenericButton
            variant={"solid"}
            size="xs"
            text={"Info"}
            onClick={() => {
              notifySimple("Success", "info");
            }}
            color="secondary"
          />
        </div>
        <div className="mt-4 flex gap-5">
          <GenericButton
            variant={"outline"}
            size="lg"
            text={"Success"}
            onClick={() => {
              notifySimple("Success", "success");
            }}
            color="secondary"
          />
          <GenericButton
            variant={"outline"}
            size="md"
            text={"Error"}
            onClick={() => {
              notifySimple("Error", "error");
            }}
            color="secondary"
          />
          <GenericButton
            variant={"outline"}
            size="sm"
            text={"Warning"}
            onClick={() => {
              notifySimple("Warning", "warning");
            }}
            color="secondary"
          />
          <GenericButton
            variant={"outline"}
            size="xs"
            text={"Info"}
            onClick={() => {
              notifySimple("Success", "info");
            }}
            color="secondary"
          />
        </div>
        <div className="mt-4 flex gap-5">
          <GenericButton
            variant={"ghost"}
            size="lg"
            text={"Success"}
            onClick={() => {
              notifySimple("Success", "success");
            }}
            color="secondary"
          />
          <GenericButton
            variant={"ghost"}
            size="md"
            text={"Error"}
            onClick={() => {
              notifySimple("Error", "error");
            }}
            color="secondary"
          />
          <GenericButton
            variant={"ghost"}
            size="sm"
            text={"Warning"}
            onClick={() => {
              notifySimple("Warning", "warning");
            }}
            color="secondary"
          />
          <GenericButton
            variant={"ghost"}
            size="xs"
            text={"Info"}
            onClick={() => {
              notifySimple("Success", "info");
            }}
            color="secondary"
          />
        </div>
        <div className="mt-4 flex gap-5">
          <GenericButton
            variant={"link"}
            size="lg"
            text={"Success"}
            onClick={() => {
              notifySimple("Success", "success");
            }}
            color="secondary"
          />
          <GenericButton
            variant={"link"}
            size="md"
            text={"Error"}
            onClick={() => {
              notifySimple("Error", "error");
            }}
            color="secondary"
          />
          <GenericButton
            variant={"link"}
            size="sm"
            text={"Warning"}
            onClick={() => {
              notifySimple("Warning", "warning");
            }}
            color="secondary"
          />
          <GenericButton
            variant={"link"}
            size="xs"
            text={"Info"}
            onClick={() => {
              notifySimple("Success", "info");
            }}
            color="secondary"
          />
        </div>
      </ProfileCard>
      <ProfileCard title="Loaders" description="Example">
        <>To Do</>
      </ProfileCard>
    </div>
  );
};

export default Widgets;
