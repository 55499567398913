import { InputHTMLAttributes, forwardRef } from "react";

interface InputFieldProps extends InputHTMLAttributes<HTMLInputElement> {
  id: string;
  extra?: string;
  extraContainer?: string;
  variant?: "solid" | "outline";
  state?: "error" | "success"; // Specific states for styling
  readonly?: boolean;
  sizes?: "lg" | "md" | "sm" | "xs";
  onChange?: (val: any) => void;
  color?: "primary" | "secondary";
  rounded?: string;
  loading?: boolean;
}

const InputField = forwardRef<HTMLInputElement, InputFieldProps>(
  (
    {
      id,
      value,
      type = "text",
      placeholder,
      sizes = "lg",
      variant = "outline",
      color = "primary",
      rounded = "rounded-2xl",
      state,
      disabled,
      readonly,
      loading = false,
      extra = "",
      extraContainer = "",
      onChange,
      onBlur,
      onFocus,
      ...rest
    },
    ref
  ) => {
    const isDisabled = disabled || loading;

    const colorClasses = {
      primary: {
        solid: `text-primary bg-gray-50/10`,
        outline: `border border-brand-700 text-primary`,
      },
      secondary: {
        solid: `text-secondary bg-gray-50/10`,
        outline: `border border-secondary text-secondary`,
      },
    };

    const sizeClasses = {
      lg: "h-12 px-6 text-txt-lg",
      md: "h-10 px-4 text-txt-md",
      sm: "h-8 px-3 text-txt-sm",
      xs: "h-6 px-2 text-txt-xs",
    };

    const stateClasses = {
      error: "outline-red-500 text-red-500 placeholder:text-red-500",
      success: "outline-green-500 text-green-500 placeholder:text-green-500",
    };

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      const inputValue = e.target.value;
      const parsedValue =
        type === "number" && !isNaN(Number(inputValue))
          ? parseFloat(inputValue)
          : inputValue;

      onChange?.(parsedValue);
    };

    return (
      <div className={`${extraContainer} flex w-full`}>
        <input
          {...rest}
          ref={ref}
          id={id}
          type={type}
          value={value !== undefined ? String(value) : ""}
          placeholder={readonly ? "" : placeholder}
          readOnly={readonly}
          disabled={isDisabled}
          onChange={handleChange}
          onFocus={onFocus}
          onBlur={onBlur}
          className={`flex w-full items-center justify-center  ${rounded} ${
            sizeClasses[sizes]
          } ${colorClasses[color][variant]} ${
            state ? stateClasses[state] : "outline-none"
          } ${
            isDisabled
              ? "border-none bg-gray-50/50"
              : "hover:opacity-50 focus:opacity-100"
          } ${extra}`}
        />
      </div>
    );
  }
);

export default InputField;

// import { InputHTMLAttributes, forwardRef } from "react";

// interface InputFieldProps extends InputHTMLAttributes<HTMLInputElement> {
//   id: string;
//   extra?: string;
//   extraContainer?: string;
//   variant?: "solid" | "outline";
//   state?: "error" | "success"; // Specific states for styling
//   readonly?: boolean;
//   sizes?: "lg" | "md" | "sm" | "xs";
//   onChange?: (val: any) => void;
//   color?: "primary" | "secondary";
//   rounded?: string;
//   loading?: boolean;
// }

// // Custom components
// const InputField = forwardRef<HTMLInputElement, InputFieldProps>(
//   (props, ref) => {
//     const {
//       value,
//       id,
//       extra,
//       extraContainer,
//       sizes = "lg",
//       type = "text",
//       color = "primary",
//       rounded = "rounded-2xl",
//       loading = false,
//       placeholder,
//       variant = "outline",
//       state,
//       disabled,
//       readonly,
//       onChange,
//       onBlur,
//       onFocus,
//       ...rest
//     } = props;
//     let typeClass = "";
//     let sizeClass = "";
//     let opacityClass =
//       disabled || loading ? "bg-opacity-40 border-0" : "bg-opacity-100";

//     // Dynamically generate color classes based on the color prop
//     const colorClasses = {
//       primary: {
//         solid: `text-primary ${
//           disabled || loading
//             ? "bg-opacity-40 border-0"
//             : "border border-primary bg-transparent"
//         }`,
//         outline: `border border-primary text-primary bg-transparent ${
//           disabled || loading ? "bg-opacity-40 border-0" : "bg-opacity-100"
//         }`,
//       },
//       secondary: {
//         solid: `text-secondary ${
//           disabled || loading
//             ? "bg-opacity-40 border-0"
//             : "border border-secondary bg-transparent"
//         }`,
//         outline: `border border-secondary text-secondary bg-transparent ${
//           disabled || loading ? "bg-opacity-40 border-0" : "bg-opacity-100"
//         }`,
//       },
//     };

//     // Set typeClass based on variant and color
//     typeClass = colorClasses[color][variant];

//     switch (sizes) {
//       case "lg":
//         sizeClass = "h-12 px-6 text-txt-lg";
//         break;
//       case "md":
//         sizeClass = "h-10 px-4 text-txt-md";
//         break;
//       case "sm":
//         sizeClass = "h-8 px-3  text-txt-sm";
//         break;
//       case "xs":
//         sizeClass = "h-6 px-2  text-txt-xs";
//         break;
//     }

//     const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
//       const inputValue = e.target.value;

//       // If input type is number, parse to a number
//       const parsedValue =
//         type === "number" && !isNaN(Number(inputValue))
//           ? parseFloat(inputValue)
//           : inputValue;

//       // Call the onChange callback with the parsed value instead of the raw value
//       onChange(parsedValue);
//     };

//     return (
//       <div className={`${extraContainer} flex w-full`}>
//         <input
//           autoComplete="off"
//           onChange={handleChange}
//           onFocus={onFocus}
//           disabled={disabled}
//           readOnly={readonly}
//           type={type}
//           id={id}
//           placeholder={readonly ? "" : placeholder}
//           value={value !== undefined ? String(value) : ""}
//           onMouseDown={(e) => {
//             e.stopPropagation();
//           }}
//           onBlur={onBlur}
//           className={`flex ${sizeClass} ${typeClass} ${rounded} w-full items-center justify-center
//           ${
//             state === "error"
//               ? "border-red-500 text-red-500 outline-none placeholder:text-red-500 dark:!border-red-500 dark:!text-red-500 dark:placeholder:!text-red-500"
//               : state === "success"
//               ? "border-green-500 text-green-500 outline-none placeholder:text-green-500 dark:!border-green-400 dark:!text-green-400 dark:placeholder:!text-green-400"
//               : `outline-none hover:opacity-50 focus:opacity-100`
//           }
//           ${extra}`}
//         />
//       </div>
//     );
//   }
// );

// export default InputField;
