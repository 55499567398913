import { background } from "@chakra-ui/system";
import { dataUsage } from "api/data";
import { DataReqBody } from "api/types/data";
import { Device } from "api/types/device";
import BarChart from "components/charts/BarChart";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

import { formatDate, formatDateToZ } from "util/datelib";
import GenericCard from "widgets/cards/generic";
import Combobox from "widgets/shared/inputs/combobox";
import DatePickerField from "widgets/shared/inputs/datePicker";
import PropagateLoader from "react-spinners/PropagateLoader";
import WaterRateForm from "widgets/forms/sites/rates/water";
import SewerageRateForm from "widgets/forms/sites/rates/sewerage";
import ElectricityRateForm from "widgets/forms/sites/rates/electricity";
import LabeledField from "providers/labeledField";
import { Site } from "api/types/site";
import { setLoading } from "store/appSlice";
import { ratesRateGetActive } from "api/rates";
import { RateResponse } from "api/types/rates";
import OtherRateForm from "widgets/forms/sites/rates/other";

interface GeneralProps {
  site?: Site;
  onUpdate?: () => void;
}

const Rates: React.FC<GeneralProps> = ({ site, onUpdate }) => {
  const [loading, setLoading] = useState(false);

  const [rates, setRates] = useState<RateResponse>(null);

  const fetchData = async () => {
    setRates(null);
    setLoading(true);
    const { data, status, isLoading } = await ratesRateGetActive(site?.id);
    setLoading(isLoading);
    if (status && status === 200) {
      setRates(data.rates);
    }
  };

  useEffect(() => {
    if (site && site.id) {
      fetchData();
    }
  }, [site]);

  return (
    <div className="grid max-h-full grid-cols-1 gap-4 sm:grid-cols-1 lg:grid-cols-4">
      <GenericCard
        title="Water Rates"
        description={"Display rates"}
        bgColor="bg-white"
        // extra="h-full"
        extraTitle="text-primary heading-md"
        extraDescription="text-tertiary text-txt-sm"
      >
        <div className="flex h-full w-full flex-col gap-6 overflow-x-auto">
          {!loading && (
            <>
              <WaterRateForm
                defaultValues={rates?.water}
                submit={() => {}}
                onClose={() => {}}
                disabled
              />
            </>
          )}
        </div>
      </GenericCard>
      <GenericCard
        title="Sewerage Rates"
        description={"Display rates"}
        bgColor="bg-white"
        // extra="h-full"
        extraTitle="text-primary heading-md"
        extraDescription="text-tertiary text-txt-sm"
      >
        <div className="flex h-full w-full flex-col gap-6 overflow-x-auto">
          {!loading && (
            <>
              <SewerageRateForm
                defaultValues={rates?.sewerage}
                submit={() => {}}
                onClose={() => {}}
                disabled
              />
            </>
          )}
        </div>
      </GenericCard>
      <GenericCard
        title="Electricity Rates"
        description={"Display rates"}
        bgColor="bg-white"
        // extra="h-full"
        extraTitle="text-primary heading-md"
        extraDescription="text-tertiary text-txt-sm"
      >
        <div className="flex h-full w-full flex-col gap-6 overflow-x-auto">
          {!loading && (
            <>
              <ElectricityRateForm
                defaultValues={rates?.electricity}
                submit={() => {}}
                onClose={() => {}}
                disabled
              />
            </>
          )}
        </div>
      </GenericCard>
      <GenericCard
        title="Other Rates"
        description={"Display rates"}
        bgColor="bg-white"
        // extra="h-full"
        extraTitle="text-primary heading-md"
        extraDescription="text-tertiary text-txt-sm"
      >
        <div className="flex h-full w-full flex-col gap-6 overflow-x-auto">
          {!loading && (
            <>
              <OtherRateForm
                submit={() => {}}
                onClose={() => {}}
                disabled
                defaultValues={{ other: rates?.other }}
              />
            </>
          )}
        </div>
      </GenericCard>
    </div>
  );
};

export default Rates;
