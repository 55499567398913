import { Device } from "api/types/device";
import { useEffect, useState } from "react";
import PieChart from "components/charts/PieChart";
import { DataReqBody } from "api/types/data";
import { dataUsage } from "api/data";
import Switch from "widgets/shared/inputs/switch";
import DatePickerField from "widgets/shared/inputs/datePicker";
import Table from "widgets/shared/tables/genericTable";
import defineUsageTablecolumns from "models/tables/usagePage";
import { Updater, SortingState } from "@tanstack/react-table";
import { formatDate, formatDateToZ } from "util/datelib";
import { getPieChartUsage } from "util/hooks";
import BounceLoader from "react-spinners/ClipLoader";
import { DeviceConfiguration } from "models/devices/configuration";
import { getChartColors } from "util/device";
import { t } from "i18next";
import DeviceCard from "widgets/cards/device";

const options = {
  colors: ["var(--color-500)"],
  plotOptions: {
    radialBar: {
      hollow: {
        margin: 15,
        size: "80%",
        // image: water,
        // imageWidth: 350,
        // imageHeight: 350,
        imageClipped: false,
      },
      dataLabels: {
        showOn: "always",
        name: {
          offsetY: 20,
          show: true,
          color: "var(--color-500)",
          fontSize: "13px",
          whiteSpace: "normal",
          wordWrap: "break-word",
          maxWidth: "10px",
        },
        value: {
          offsetY: -20,
          color: "var(--color-500)",
          fontSize: "30px",
          show: true,
          formatter: function (val: string) {
            return parseFloat(val).toFixed(2) + " kl";
          },
        },
      },
    },
  },

  stroke: {
    lineCap: "round",
  },

  labels: [`Last Comms:`],
};

interface GeneralProps {
  device?: Device;
  config?: DeviceConfiguration;
}

const Daily: React.FC<GeneralProps> = ({ device, config }) => {
  const { color0, color1 } = getChartColors(config || undefined);
  const [chartOptions, setChartOptions] = useState(options);
  const columns = defineUsageTablecolumns();
  const [date, setDate] = useState<any>(new Date());
  const [data, setData] = useState<any>();
  const [dataTable, setDataTable] = useState([]);
  const [pieData, setPieData] = useState([]);
  const [display, setDisplay] = useState(false);
  const [loading, setLoading] = useState(false);

  const fetchData = async () => {
    setLoading(true);

    // Calculate from and to based on the selected date
    const from = formatDateToZ(new Date(date), false); // Start of selected date
    const to = formatDateToZ(new Date(date), true); // End of selected date

    const req: DataReqBody = {
      snr: device?.snr,
      from,
      to,
      bucket: "hour",
      sensors: [config.raw],
    };

    const { data: newData, error: err, isLoading } = await dataUsage(req);
    setLoading(isLoading);

    if (!err) {
      const arr = [];
      let sum = 0;
      for (let index = 0; index < newData.length; index++) {
        const element = newData[index];
        const usage =
          config.units === "water"
            ? element[config.raw] / 1000
            : element[config.raw];
        arr.push({
          timestamp: formatDate(element.timestamp, "lastcomms"),
          raw: `${usage.toFixed(2)} ${t("device.units", {
            context: config.units,
          })}`,
        });
        sum = sum + usage;
      }

      const updatedOptions = {
        ...chartOptions,
        colors: [color0, color1],
        plotOptions: {
          ...chartOptions.plotOptions,
          radialBar: {
            ...chartOptions.plotOptions.radialBar,
            dataLabels: {
              ...chartOptions.plotOptions.radialBar.dataLabels,
              name: {
                ...chartOptions.plotOptions.radialBar.dataLabels.name,
                color: color0,
              },
              value: {
                ...chartOptions.plotOptions.radialBar.dataLabels.value,
                color: color0,
                formatter: function (val: string) {
                  return `${sum.toFixed(2)} ${t("device.units", {
                    context: config.units,
                  })}`;
                },
              },
            },
          },
        },
        labels: [
          `${t("chart.pie.lastcomms")} ${formatDate(
            device?.lastComms,
            "lastcomms"
          )}`,
        ],
      };

      // Update state for options and data
      setChartOptions((prevOptions) => ({
        ...prevOptions,
        ...updatedOptions, // Ensure you are spreading existing options
      }));

      setDataTable(arr);
      setPieData([
        getPieChartUsage(
          sum,
          config.units === "water"
            ? device?.dailyUsageLimit / 1000
            : device?.dailyUsageLimit
        ),
      ]);

      setData(sum);
    }
  };

  useEffect(() => {
    if (device?.snr) {
      fetchData();
    }
  }, [device, date]);

  return (
    <DeviceCard
      title={t("chart.usage.daily.title")}
      subTitle={formatDate(date, "ddMMMyyyy")}
      topRight={
        <>
          <Switch
            id={`switch-0`}
            labelKey={t("chart.usage.daily.switch")}
            valueKey={"value"}
            onChange={(val: any) => setDisplay(!display)}
            sizes="md"
          />

          <DatePickerField
            id={"1"}
            sizes="xs"
            iconOnly
            value={date}
            onChange={(val) => {
              setDate(val); // Set new date and trigger useEffect
            }}
            maxDate={new Date()}
          />
        </>
      }
    >
      <div className="flex h-full w-full items-center justify-center ">
        {dataTable.length > 0 && !loading ? (
          <>
            {!display && (
              <PieChart
                chartData={pieData}
                chartOptions={chartOptions}
                label={data}
                lastComms={device?.lastComms}
              />
            )}
            {display && (
              <div className="h-80 w-full overflow-auto">
                <Table
                  data={dataTable}
                  columns={columns}
                  pagination={{ pageIndex: 0, pageSize: 100 }}
                  paginationOptions={{
                    onPaginationChange: (pagination) => {},
                    rowCount: 100,
                    pageCount: 1,
                  }}
                  sorting={[{ desc: false, id: "name" }]}
                  onSortingChange={(
                    updaterOrValue: Updater<SortingState>
                  ): void => {
                    throw new Error("Function not implemented.");
                  }}
                />
              </div>
            )}
          </>
        ) : (
          <div className="flex items-center justify-center bg-opacity-50">
            {loading ? (
              <BounceLoader loading={loading} />
            ) : (
              <div className="text-center text-gray-500">
                No Data available make image Doubleshift
              </div>
            )}
          </div>
        )}
      </div>
    </DeviceCard>
  );
};

export default Daily;
