import GenericCard from "widgets/cards/generic";
import SetupForm from "widgets/forms/reports/setup";

interface MyDocumentProps {
  onData: (data: any) => void;
}

const Configuration: React.FC<MyDocumentProps> = ({ onData }) => {
  return (
    <GenericCard
      title="Report Settings"
      description="Configure report settings here"
      bgColor="bg-brand-500"
      extraTitle="text-secondary heading-lg"
      extraDescription="text-secondary text-txt-md"
    >
      <SetupForm
        submit={(data) => {
          onData(data);
        }}
      />
    </GenericCard>
  );
};

export default Configuration;
