import React, { useEffect, useState } from "react";
import {
  PDFViewer,
  Document,
  Page,
  Text,
  View,
  StyleSheet,
  Image,
  Font,
  Circle,
} from "@react-pdf/renderer";
import CircleButton from "widgets/shared/buttons/circle-button";
import { MdFileDownload } from "react-icons/md";
import { ReportResponse } from "api/types/report";
import Default from "assets/img/account/Diploma.png";
import { formatDate } from "util/datelib";
import * as XLSX from "xlsx";

Font.register({
  family: "Helvetica",
  src: "assets/fonts/Helvetica-Bold.ttf", // Replace with the correct path to your font file
});

// Define styles for the PDF
const styles = StyleSheet.create({
  page: {
    flexDirection: "column",
    paddingTop: 40,
    paddingBottom: 40,
    paddingHorizontal: 20,
    backgroundColor: "#FFFFFF",
  },
  headerContainer: {
    flexDirection: "row",
    justifyContent: "space-between",
    padding: 10,
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    fontSize: 10,
    fontWeight: 600,
    fontFamily: "Helvetica",
  },
  footerContainer: {
    flexDirection: "row",
    justifyContent: "space-between",
    position: "absolute",
    padding: 10,
    bottom: 0,
    left: 0,
    right: 0,
    fontSize: 10,
    fontWeight: 600,
    fontFamily: "Helvetica",
  },
  leftText: {
    flex: 1,
    textAlign: "left",
  },
  centerText: {
    flex: 1,
    textAlign: "center",
  },
  rightText: {
    flex: 1,
    textAlign: "right",
    justifyContent: "center",
    alignItems: "flex-end",
  },

  titleContainer: {
    flexDirection: "row",
    justifyContent: "space-between",
  },
  row: {
    flexDirection: "row",
    alignItems: "flex-start",
    marginBottom: 0, // spacing between rows
  },
  label: {
    width: 120, // set a fixed width for labels to align values consistently
    fontSize: 12,
    fontWeight: "bold",
    textAlign: "left",
    fontFamily: "Helvetica",
  },
  value: {
    flex: 1, // takes up remaining space
    textAlign: "left",
    fontSize: 10,
    fontFamily: "Helvetica",
  },

  title: {
    fontSize: 18,
    marginTop: 10,
    fontWeight: 600,
    fontFamily: "Helvetica",
  },
  title0: {
    fontSize: 14,
    marginTop: 10,
    fontWeight: 600,
    fontFamily: "Helvetica",
  },
  subTitle: { fontSize: 10, fontFamily: "Helvetica" },
  image: {
    width: 100,
    height: 100,
  },

  table: {
    marginTop: 10,
    width: "100%",
    overflow: "hidden", // Ensures no content overflows
  },
  tableHeader: {
    flexDirection: "row",
    paddingBottom: 5,
    marginBottom: 5,
    fontWeight: 600,
    borderBottomWidth: 1,
    borderBottomColor: "#000",
    fontFamily: "Helvetica",
    wordBreak: "normal", // Ensure words do not break
  },
  tableRow: {
    flexDirection: "row",
    paddingVertical: 1,
  },
  tableCol: {
    minWidth: 60, // Minimum column width
    flexGrow: 1, // Allows columns to expand based on content
    flexShrink: 1, // Allows columns to shrink based on content
    paddingRight: 2,
    fontSize: 8,
    fontWeight: 400,
    fontFamily: "Helvetica",
    textAlign: "left", // Align text to the left
    // overflow: "hidden", // Ensures no content overflows
    wordBreak: "normal", // Ensure words do not break
  },
  errorRow: {
    backgroundColor: "red", // Set background to red for error rows
  },
});

const formatValue = (header: any, value: string | number | Date) => {
  switch (header) {
    case "created":
    case "lastComm":
      return formatDate(new Date(value), "lastcomms"); // Format the date and convert it to a string

    default:
      try {
        return String(value);
      } catch {
        return "n/A";
      }
  }
};

// Export data to CSV
// Export data to CSV with titleContainer values as headers
const exportToCSV = (data: any, reportDetails: any) => {
  if (!data) return;

  // Define titleContainer values as headers
  const titleHeaders = [
    ["Account Name:", reportDetails?.accountName || ""],
    ["Site Name:", reportDetails?.siteName || ""],
    [
      "From Date:",
      reportDetails?.fromDate
        ? formatDate(reportDetails.fromDate, "ddMMMyyyy")
        : "",
    ],
    [
      "To Date:",
      reportDetails?.toDate
        ? formatDate(reportDetails.toDate, "ddMMMyyyy")
        : "",
    ],
    ["Device Details:", reportDetails?.deviceDetails?.meterName || ""],
  ]
    .map(([label, value]) => `${label}, ${value}`)
    .join("\n");

  // Extract column headers from data
  const headers = Object.keys(data[0]).join(",") + "\n";
  const rows = data
    .map((item: { [s: string]: unknown } | ArrayLike<unknown>) =>
      Object.values(item).join(",")
    )
    .join("\n");

  const csvContent = `${titleHeaders}\n\n${headers}${rows}`;

  const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
  const url = URL.createObjectURL(blob);

  const link = document.createElement("a");
  link.href = url;
  link.setAttribute("download", "data.csv");
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

const exportToExcel = (data: any, reportDetails: any, appendices: any[]) => {
  if (!data) return;

  // Define titleContainer values as headers
  const titleHeaders = [
    ["Account Name:", reportDetails?.accountName || ""],
    ["Site Name:", reportDetails?.siteName || ""],
    [
      "From Date:",
      reportDetails?.fromDate
        ? formatDate(reportDetails.fromDate, "ddMMMyyyy")
        : "",
    ],
    [
      "To Date:",
      reportDetails?.toDate
        ? formatDate(reportDetails.toDate, "ddMMMyyyy")
        : "",
    ],
    ["Device Details:", reportDetails?.deviceDetails?.meterName || ""],
  ];

  // Create main worksheet
  const mainSheetData = [
    ...titleHeaders, // Title headers
    [], // Empty row for spacing
    Object.keys(data[0]), // Data headers
    ...data.map((item: { [s: string]: unknown }) => Object.values(item)), // Data rows
  ];
  const mainSheet = XLSX.utils.aoa_to_sheet(mainSheetData);

  // Create a new workbook and append the main sheet
  const wb = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(wb, mainSheet, "Report");

  // Add appendices as new sheets
  appendices.forEach(
    (appendix: { title: string; group: string; data: any[] }) => {
      const appendixData = Array.isArray(appendix.data) ? appendix.data : []; // Ensure data is an array
      const appendixSheetData = [
        [appendix.group, appendix.title], // Title row
        [], // Empty row for spacing
        appendixData.length > 0 ? Object.keys(appendixData[0]) : [], // Data headers if available
        ...appendixData.map((item: { [s: string]: unknown }) =>
          Object.values(item)
        ), // Data rows
      ];
      const appendixSheet = XLSX.utils.aoa_to_sheet(appendixSheetData);
      XLSX.utils.book_append_sheet(
        wb,
        appendixSheet,
        `${appendix.group} ${appendix.title}` || "Appendix"
      );
    }
  );

  // Generate Excel file
  const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });

  // Create a Blob from the Excel data and download it
  const blob = new Blob([excelBuffer], { type: "application/octet-stream" });
  const url = URL.createObjectURL(blob);

  const link = document.createElement("a");
  link.href = url;
  link.setAttribute("download", "data.xlsx");
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

interface MyDocumentProps {
  report: ReportResponse;
}

const ReportViewer: React.FC<MyDocumentProps> = ({ report }) => {
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (report) {
      setIsLoading(true);
    }
  }, [report]);
  if (!report) {
    // Display a fallback UI if report is undefined
    return <Text></Text>;
  }

  const { reportDetails, header, footer, data, appendices } = report;

  const handleRenderComplete = () => {
    setIsLoading(false);
  };
  // Dynamically retrieve headers from the first item
  const items = data || [];
  const deviceDetails = reportDetails?.deviceDetails || {};
  const headers = Object.keys(items[0] || {});

  return (
    <>
      {items.length > 0 && headers.length > 0 ? (
        <>
          <div className="mb-2 flex justify-end gap-2">
            {/* <CircleButton
              size="sm"
              variant="outline"
              icon={MdFileDownload}
              label={"Export to CSV"}
              onClick={() => exportToCSV(items, reportDetails)}
            /> */}
            <CircleButton
              size="sm"
              variant="outline"
              icon={MdFileDownload}
              label={"Export to Excel"}
              onClick={() => exportToExcel(items, reportDetails, appendices)}
            />
          </div>
          {isLoading && (
            <div className="flex h-40 items-center justify-center">
              <p>Loading PDF...</p>
            </div>
          )}

          <PDFViewer style={{ width: "100%", height: "70.5vh" }}>
            <Document title="HELLO" onRender={() => handleRenderComplete()}>
              <Page style={styles.page} size={"A4"} orientation="landscape">
                {/* Header */}
                <View style={styles.headerContainer} fixed>
                  <Text style={styles.leftText}>{header?.snrOrSite}</Text>
                  <Text style={styles.centerText}>{header?.title}</Text>
                  <Text style={styles.rightText}>{header?.accountName}</Text>
                </View>

                {/* Title (Outside Header) */}
                <View style={styles.titleContainer}>
                  <View style={styles.leftText}>
                    {reportDetails?.title && (
                      <Text style={styles.title}>{reportDetails?.title}</Text>
                    )}

                    <View style={styles.row}>
                      {reportDetails?.accountName && (
                        <>
                          <Text style={styles.label}>Account Name:</Text>
                          <Text style={styles.value}>
                            {reportDetails?.accountName}
                          </Text>
                        </>
                      )}
                    </View>

                    <View style={styles.row}>
                      {reportDetails?.siteName && (
                        <>
                          <Text style={styles.label}>Site Name:</Text>
                          <Text style={styles.value}>
                            {reportDetails?.siteName}
                          </Text>
                        </>
                      )}
                    </View>

                    <View style={styles.row}>
                      {reportDetails?.fromDate && (
                        <>
                          <Text style={styles.label}>From Date:</Text>
                          <Text style={styles.value}>
                            {formatDate(reportDetails?.fromDate, "ddMMMyyyy")}
                          </Text>
                        </>
                      )}
                    </View>

                    <View style={styles.row}>
                      {reportDetails?.toDate && (
                        <>
                          <Text style={styles.label}>To Date:</Text>
                          <Text style={styles.value}>
                            {formatDate(reportDetails?.toDate, "ddMMMyyyy")}
                          </Text>
                        </>
                      )}
                    </View>

                    <Text style={styles.title0}>Device Details:</Text>

                    <View style={styles.row}>
                      {deviceDetails?.meterName && (
                        <>
                          <Text style={styles.label}>Meter Name:</Text>
                          <Text style={styles.value}>
                            {deviceDetails?.meterName}
                          </Text>
                        </>
                      )}
                    </View>

                    <View style={styles.row}>
                      {deviceDetails?.meterNumber && (
                        <>
                          <Text style={styles.label}>Meter Number:</Text>
                          <Text style={styles.value}>
                            {deviceDetails?.meterNumber}
                          </Text>
                        </>
                      )}
                    </View>

                    <View style={styles.row}>
                      {deviceDetails?.snr && (
                        <>
                          <Text style={styles.label}>Serial Number:</Text>
                          <Text style={styles.value}>{deviceDetails?.snr}</Text>
                        </>
                      )}
                    </View>

                    <View style={styles.row}>
                      {deviceDetails?.lastComms && (
                        <>
                          <Text style={styles.label}>Last Comms:</Text>
                          <Text style={styles.value}>
                            {formatDate(deviceDetails?.lastComms, "lastcomms")}
                          </Text>
                        </>
                      )}
                    </View>

                    <View style={styles.row}>
                      {deviceDetails?.deviceCount && (
                        <>
                          <Text style={styles.label}>Dev Count:</Text>
                          <Text style={styles.value}>
                            {deviceDetails?.deviceCount}
                          </Text>
                        </>
                      )}
                    </View>
                  </View>
                  <View style={styles.rightText}>
                    <Image
                      src={reportDetails?.logo || Default}
                      style={styles.image}
                    />
                  </View>
                </View>

                <View style={styles.table}>
                  {/* Table Header */}
                  <View style={[styles.tableHeader]} fixed>
                    {headers
                      .filter((header) => header !== "ErrorCodes") // Exclude the ATOM_REPORT_FLAG column
                      .map((header, index) => (
                        <Text key={index} style={styles.tableCol}>
                          {header
                            .replace(/-/g, " ")
                            .replace(/\b\w/g, (char) => char.toUpperCase())}
                        </Text>
                      ))}
                  </View>

                  {/* Table Rows */}
                  {items.map((item, rowIndex) => (
                    <View
                      style={[
                        styles.tableRow,
                        item?.errorCodes?.length > 0 ? styles.errorRow : null, // Conditionally set background color to red
                      ]}
                      key={rowIndex}
                    >
                      {headers
                        .filter((header) => header !== "ErrorCodes") // Exclude the ATOM_REPORT_FLAG column
                        .map((header, colIndex) => (
                          <Text key={colIndex} style={styles.tableCol}>
                            {formatValue(header, item[header])}
                          </Text>
                        ))}
                    </View>
                  ))}
                </View>

                {/* Footer */}
                <View style={styles.footerContainer} fixed>
                  <Text style={styles.leftText}>{footer?.siteName}</Text>
                  <Text
                    render={({ pageNumber, totalPages }) =>
                      `Page ${pageNumber} of ${totalPages}`
                    }
                    fixed
                    style={styles.centerText}
                  />
                  <Text style={styles.rightText}></Text>
                </View>
              </Page>

              {appendices &&
                appendices.length > 0 &&
                appendices.map((page: any, index: any) => {
                  const aHeaders = Object.keys(page?.data[0] || {});
                  const aRows = page?.data;
                  return (
                    <Page
                      style={styles.page}
                      size={"A4"}
                      orientation="landscape"
                    >
                      {/* Header */}
                      <View style={styles.headerContainer} fixed>
                        <Text style={styles.leftText}>{header?.snrOrSite}</Text>
                        <Text style={styles.centerText}>{header?.title}</Text>
                        <Text style={styles.rightText}>
                          {header?.accountName}
                        </Text>
                      </View>

                      <View style={styles.titleContainer}>
                        {page?.title && (
                          <Text
                            style={styles.title}
                          >{`${page.group}: ${page.title}`}</Text>
                        )}
                      </View>

                      {/* Appendices */}
                      <View style={styles.table}>
                        {/* Table Header */}
                        <View style={[styles.tableHeader]} fixed>
                          {aHeaders.map((header, index) => (
                            <Text key={index} style={styles.tableCol}>
                              {header
                                .replace(/-/g, " ")
                                .replace(/\b\w/g, (char) => char.toUpperCase())}
                            </Text>
                          ))}
                        </View>

                        {/* Table Rows */}
                        {aRows &&
                          aRows.length > 0 &&
                          aRows.map(
                            (
                              item: { [x: string]: string | number | Date },
                              rowIndex: React.Key
                            ) => (
                              <View style={[styles.tableRow]} key={rowIndex}>
                                {aHeaders.map((header, colIndex) => (
                                  <Text key={colIndex} style={styles.tableCol}>
                                    {formatValue(header, item[header])}
                                  </Text>
                                ))}
                              </View>
                            )
                          )}
                      </View>

                      {/* Footer */}
                      <View style={styles.footerContainer} fixed>
                        <Text style={styles.leftText}>{footer?.siteName}</Text>
                        <Text
                          render={({ pageNumber, totalPages }) =>
                            `Page ${pageNumber} of ${totalPages}`
                          }
                          fixed
                          style={styles.centerText}
                        />
                        <Text style={styles.rightText}></Text>
                      </View>
                    </Page>
                  );
                })}
            </Document>
          </PDFViewer>
        </>
      ) : (
        <div>no data doubleshift image</div>
      )}
    </>
  );
};

export default ReportViewer;
