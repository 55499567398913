import { uvsDisbursements, uvsUploadXlsx } from "api/admin";
import LabeledField from "providers/labeledField";
import { notifySimple } from "providers/toast";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { setLoading } from "store/appSlice";

import { formatDate, formatDateToZ } from "util/datelib";
import GenericCard from "widgets/cards/generic";
import GenericButton from "widgets/shared/buttons/generic";
import DateRangePickerField from "widgets/shared/inputs/dateRangePicker";
import UploadFile from "widgets/shared/uploadfile";
import Uvs from "./uvs/page";
import Rates from "./rates/page";
import {
  Accordion,
  AccordionButton,
  AccordionItem,
  AccordionPanel,
} from "@chakra-ui/accordion";
import { ViewPort } from "models/constants/styling";

type LooseValue = Date | [Date, Date] | null;

type Disbursements = {
  link: string;
  name: string;
};

const Support = () => {
  const dispatch = useDispatch();

  const [dateRange, setDateRange] = useState<LooseValue>(null);
  const [disbursements, setDisbursements] = useState<Disbursements[]>([]);

  const handleUploadFile = async (e: any) => {
    dispatch(setLoading(true));
    const { data, isLoading, error } = await uvsUploadXlsx(e);
    dispatch(setLoading(isLoading));

    // Check if the API response contains a URL to download
    if (!error) {
      notifySimple("success", "success");
    } else {
      notifySimple("error", "error");
    }
  };

  const handleDownloadFile = (item: Disbursements) => {
    window.open(item.link, "_blank"); // Open the URL in a new tab
  };

  const handleGetDisbursements = async () => {
    if (dateRange && Array.isArray(dateRange)) {
      const from = formatDateToZ(new Date(dateRange[0]), false); // Start of selected date
      const to = formatDateToZ(new Date(dateRange[1]), true); // End of selected date

      dispatch(setLoading(true));

      const { data, isLoading } = await uvsDisbursements(from, to);
      dispatch(setLoading(isLoading));

      // Check if the API response contains a URL to download
      if (data && data.data) {
        setDisbursements(data.files);
      }
    } else {
      notifySimple("Invalid date range selected", "error");
    }
  };

  return (
    <div className={`mt-3 grid h-full grid-cols-1 gap-4`}>
      <Accordion
        allowToggle
        key={"main"}
        className={` ${ViewPort.contentFitHeight}`}
      >
        <AccordionItem className="my-2 h-12 rounded-xl bg-white" key={"0"}>
          <AccordionButton className="heading-md mb-1 h-12 items-center justify-center text-primary">
            UVS
          </AccordionButton>
          <AccordionPanel className="bg-white">
            <Uvs />
          </AccordionPanel>
        </AccordionItem>
        <AccordionItem className="my-2 h-12 rounded-xl bg-white" key={"1"}>
          <AccordionButton className="heading-md mb-1 h-12 items-center justify-center text-primary">
            Rates
          </AccordionButton>
          <AccordionPanel>
            <Rates />
          </AccordionPanel>
        </AccordionItem>
      </Accordion>
    </div>
  );
};

export default Support;
