import * as z from "zod";

import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";

import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "../../../providers/form";
import InputField from "widgets/shared/inputs/inputField";
import { useTranslation } from "react-i18next";
import i18next from "i18next";
import PhoneNumber from "widgets/shared/inputs/phoneNumber";

import { PhoneNumberUtil } from "google-libphonenumber";
import GenericButton from "widgets/shared/buttons/generic";

const phoneUtil = PhoneNumberUtil.getInstance();

// Schema
const InformationSchema = z.object({
  email: z.string().readonly(),
  name: z.string().min(1, {
    message: i18next.t("zod.error.required"),
  }),
  family_name: z.string().min(1, {
    message: i18next.t("zod.error.required"),
  }),
  phone_number: z
    .string()
    .min(5, {
      message: i18next.t("zod.error.phone.number"),
    })
    .refine(
      (phone_number) => {
        try {
          return phoneUtil.isValidNumber(
            phoneUtil.parseAndKeepRawInput(phone_number)
          );
        } catch (error) {
          return false;
        }
      },
      {
        message: i18next.t("zod.error.phone.valid"),
      }
    ),
});

export type InformationFormType = z.infer<typeof InformationSchema>;

// Props
type InformationFormProps = {
  submit: (data: InformationFormType) => void;
  defaultValues?: InformationFormType;
  values?: InformationFormType;
  loading?: boolean;
};

const InformationForm = ({
  submit,
  defaultValues,
  values,
  loading,
}: InformationFormProps) => {
  const form = useForm<InformationFormType>({
    resolver: zodResolver(InformationSchema),
    defaultValues: {
      email: "",
      name: "",
      family_name: "",
      phone_number: "",
    },
    values,
  });

  const { t } = useTranslation();
  const { errors, isDirty } = form.formState;

  const onSubmit = async (formData: InformationFormType) => {
    try {
      await submit(formData);
    } catch (error) {
      console.error("Error submitting form:", error);
    }
  };

  const clearErrors = () => {
    form.clearErrors();
  };

  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-2">
        <div className="grid grid-cols-1 gap-2 md:grid-cols-2 md:gap-5">
          <FormField
            control={form.control}
            name="name"
            rules={{ required: false }}
            render={({ field }) => (
              <FormItem>
                <FormLabel>{t("form.input.name")}</FormLabel>
                <FormControl>
                  <InputField
                    placeholder={t("form.placeholder.name")}
                    id="name"
                    type="text"
                    state={errors.name ? "error" : undefined}
                    onFocus={clearErrors}
                    {...field}
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="family_name"
            rules={{ required: false }}
            render={({ field }) => (
              <FormItem>
                <FormLabel>{t("form.input.family.name")}</FormLabel>
                <FormControl>
                  <InputField
                    placeholder={t("form.placeholder.family.name")}
                    id="family_name"
                    type="text"
                    state={errors.family_name ? "error" : undefined}
                    onFocus={clearErrors}
                    {...field}
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
        </div>
        <FormField
          control={form.control}
          name="email"
          rules={{ required: false }}
          render={({ field }) => (
            <FormItem>
              <FormLabel>{t("form.input.email")}</FormLabel>
              <FormControl>
                <InputField
                  placeholder={t("form.placeholder.email")}
                  id="email"
                  type="text"
                  readonly
                  state={errors.email ? "error" : undefined}
                  {...field}
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />

        <FormField
          control={form.control}
          name="phone_number"
          rules={{ required: false }}
          render={({ field }) => (
            <FormItem>
              <FormLabel>{t("form.input.phone.number")}</FormLabel>
              <FormControl>
                <PhoneNumber
                  id="phone_number"
                  placeholder={t("form.placeholder.phone.number")}
                  state={errors.phone_number ? "error" : undefined}
                  onFocus={clearErrors}
                  value={field.value}
                  onChang={field.onChange}
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <div className="flex w-full flex-col pt-2">
          <div className="flex justify-end">
            <GenericButton
              text={t("button.action.save.changes")}
              disabled={!isDirty}
              loading={loading || false}
              type="submit"
              size="md"
            />
          </div>
        </div>
      </form>
    </Form>
  );
};

export default InformationForm;
