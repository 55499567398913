import { t } from "i18next";
import { DeviceConfiguration } from "models/devices/configuration";
import { IconType } from "react-icons";
import { getDeviceBgColor, getDeviceIcon } from "util/device";

interface GeneralProps {
  config?: DeviceConfiguration;
  title?: string;
  subTitle?: string;
  showIcon?: boolean;
  topRight?: React.ReactNode;
  children?: React.ReactNode;
}

const DeviceCard: React.FC<GeneralProps> = ({
  config,
  title,
  subTitle,
  showIcon = false,
  topRight,
  children,
}) => {
  const Icon: IconType | null = config?.units
    ? getDeviceIcon(config?.units)
    : null;

  return (
    <div
      className={`!z-5 relative flex h-full w-full flex-col rounded-[20px] ${getDeviceBgColor(
        config?.units || ""
      )} p-4 md:p-6`}
    >
      <div className="mb-4 flex w-full gap-2">
        <>
          {showIcon && config?.units && Icon && (
            <Icon className="heading-3xl rounded-xl bg-white text-primary" />
          )}
        </>

        <div
          className={`${
            config ? "text-secondary" : "text-primary"
          } flex w-full flex-row justify-between md:flex-row md:items-start`}
        >
          <div className="flex flex-col">
            <h4 className={`heading-md`}>{title}</h4>
            <p className={`mt-0 text-txt-md`}>{subTitle}</p>
          </div>

          <div className={`mt-0 flex flex-col gap-2 md:flex-row`}>
            {topRight}
          </div>
        </div>
      </div>
      {children}
    </div>
  );
};

export default DeviceCard;
