import { InputHTMLAttributes, useEffect, useState } from "react";
import { t } from "i18next";
import { MdSearch } from "react-icons/md";

export function SearchInput({
  value: initialValue,
  onChange,
  debounce = 500,
  sizes = "lg",
  rounded = "rounded-2xl",
  extra,
  ...props
}: {
  value: any;
  onChange: (value: any) => void;
  debounce?: number;
  sizes?: "lg" | "md" | "sm" | "xs";
  extra?: string;
  rounded?: string;
} & Omit<InputHTMLAttributes<HTMLInputElement>, "onChange">) {
  const [value, setValue] = useState<any>(initialValue);

  useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      onChange(value);
    }, debounce);

    return () => clearTimeout(timeout);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  let sizeClass = "";

  switch (sizes) {
    case "lg":
      sizeClass = "h-12 px-2 text-txt-lg";
      break;
    case "md":
      sizeClass = "h-10 px-2 text-txt-md";
      break;
    case "sm":
      sizeClass = "h-8 px-2  text-txt-sm";
      break;
    case "xs":
      sizeClass = "h-6 px-2  text-txt-xs";
      break;
  }

  return (
    <div
      className={`flex w-full items-center px-3 text-primary ${rounded} bg-gray-50 ${sizeClass} ${extra}`}
    >
      <MdSearch />
      <input
        {...props}
        placeholder={t("generic.action.search") + "..."}
        className={`block ${sizeClass} w-full rounded-2xl bg-gray-50  hover:border-none hover:outline-none focus:border-none focus:outline-none active:border-none active:outline-none dark:text-darkPrimary ${extra}`}
        value={value ?? ""}
        onChange={(e) => {
          if (e.target.value === "") return setValue("");

          setValue(e.target.value);
        }}
      />
    </div>
  );
}
