import * as z from "zod";

import { useForm, useWatch } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";

import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "../../../../providers/form";
import InputField from "widgets/shared/inputs/inputField";
import { useTranslation } from "react-i18next";
import i18next from "i18next";
import GenericButton from "widgets/shared/buttons/generic";
import { useEffect } from "react";
// Schema
const WaterConfigurationSchema = z.object({
  dailyUsageLimit: z.any(),
});

export type WaterConfigurationFormType = z.infer<
  typeof WaterConfigurationSchema
>;

// Props
type WaterConfigurationFormProps = {
  submit: (data: WaterConfigurationFormType) => void;
  onClose: () => void;
  defaultValues?: WaterConfigurationFormType;
  values?: WaterConfigurationFormType;
  loading?: boolean;
  disabled?: boolean;
};

const WaterConfigurationForm = ({
  submit,
  onClose,
  defaultValues,
  values,
  loading,
  disabled,
}: WaterConfigurationFormProps) => {
  const form = useForm<WaterConfigurationFormType>({
    resolver: zodResolver(WaterConfigurationSchema),
    defaultValues: {
      dailyUsageLimit: 0,
    },
    values,
  });

  const { t } = useTranslation();
  const { errors, isDirty } = form.formState;

  const clearErrors = () => {
    form.clearErrors();
  };

  // Automatically submit the form if valid
  const onSubmit = async (formData: WaterConfigurationFormType) => {
    try {
      await submit(formData);
    } catch (error) {
      console.error("Error submitting form:", error);
    }
  };

  useEffect(() => {
    if (disabled && isDirty) {
      form.reset(values || defaultValues);
    }
  }, [disabled, isDirty, form, values, defaultValues]);

  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)} className=" space-y-2">
        <div className="grid grid-cols-1 gap-2 md:grid-cols-2 md:gap-5">
          <FormField
            control={form.control}
            name="dailyUsageLimit"
            rules={{ required: false }}
            render={({ field }) => (
              <FormItem>
                <FormLabel className="text-secondary">
                  {t("form.input.daily.limit", { context: "water" })}
                </FormLabel>
                <FormControl>
                  <InputField
                    sizes={"md"}
                    placeholder={t("form.placeholder.daily.limit", {
                      context: "water",
                    })}
                    id="daily-usage-limit"
                    type="number"
                    state={errors.dailyUsageLimit ? "error" : undefined}
                    onFocus={clearErrors}
                    disabled={disabled}
                    color="secondary"
                    variant="solid"
                    {...field}
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
        </div>

        {isDirty && !disabled && (
          <div className="flex w-full flex-col pt-4">
            <div className="flex justify-end">
              <GenericButton
                text={t("button.action.save.changes")}
                disabled={!isDirty}
                loading={loading || false}
                type="submit"
                color="secondary"
              />
            </div>
          </div>
        )}
      </form>
    </Form>
  );
};

export default WaterConfigurationForm;
