import { userUnlink } from "api/user";
import { notifySimple } from "providers/toast";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { Router, useNavigate } from "react-router-dom";
import { setLoading } from "store/appSlice";
import ProfileCard from "widgets/cards/profile";
import GenericDialog from "widgets/dialogs/generic";
import GenericButton from "widgets/shared/buttons/generic";

const Delete = ({ email = "" }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);

  const toggleDialog = () => {
    setOpen(!open);
  };

  const doUnlink = async () => {
    setOpen(!open);

    dispatch(setLoading(true));
    const { data, error, isLoading, status } = await userUnlink(email);
    dispatch(setLoading(isLoading));

    if (status === 200) {
      notifySimple("Success", "success");
      navigate("/admin/users", { replace: true });
    }
  };

  return (
    <>
      <ProfileCard
        title="Unlink User"
        description="Unlink user from this account"
        bgColor="bg-brand-400"
        textColor="text-secondary"
        extra="h-full"
      >
        <div className="flex h-full w-full flex-col">
          <div className="flex-grow"></div>
          <div className="flex justify-end">
            <GenericButton
              variant={"outline"}
              size="md"
              text="Unlink"
              onClick={toggleDialog}
              color="secondary"
            />
          </div>
        </div>
      </ProfileCard>

      <GenericDialog
        isOpen={open}
        title={"Warning"}
        onClose={toggleDialog}
        onDelete={doUnlink}
      >
        <div>Are you sure you want to Unlink this user from the account?</div>
      </GenericDialog>
    </>
  );
};

export default Delete;
