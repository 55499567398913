import Banner from "./components/Banner";
import Delete from "./components/Delete";
import Information from "./components/Information";
import Password from "./components/Password";
import TwoFactor from "./components/TwoFactor";

const Profile = () => {
  return (
    <div
      className={`mt-0 grid h-fit w-full grid-cols-1 gap-y-4 md:mt-6 md:h-contentFitHeight`}
    >
      <div className="flex flex-col gap-4 lg:flex-row">
        <div className="flex-1 md:flex-grow-[4] lg:flex-grow-[3]">
          <Banner />
        </div>

        <div className="flex-1 lg:flex-grow-[4]">
          <TwoFactor />
        </div>

        <div className="flex-1 lg:flex-grow-[2]">
          <Delete />
        </div>
      </div>

      <div className="flex flex-col gap-4">
        <div className="flex flex-col gap-4 md:flex-row">
          <Information />
          <Password />
        </div>
      </div>
    </div>
  );
};

export default Profile;
