import * as z from "zod";

import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";

import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "../../../providers/form";
import { useTranslation } from "react-i18next";
import i18next from "i18next";
import InputField from "widgets/shared/inputs/inputField";
import PinInputs from "widgets/shared/inputs/pinInput";
import GenericButton from "widgets/shared/buttons/generic";

// Schema
const ConfirmForgotPasswordSchema = z
  .object({
    code: z.string().min(6),
    newPassword: z
      .string()
      .min(8, {
        message: i18next.t("zod.error.password"),
      })
      .refine(
        (newPassword) => {
          // Check if newPassword contains at least one number and one special character
          const hasNumber = /[0-9]/.test(newPassword);
          const hasSpecialCharacter =
            /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/.test(newPassword);
          return hasNumber && hasSpecialCharacter;
        },
        {
          message: i18next.t("zod.error.password.match"),
        }
      ),
    confirmPassword: z.string().min(1, {
      message: i18next.t("zod.error.required"),
    }),
  })
  .refine(
    (data) => {
      return data.confirmPassword === data.newPassword;
    },
    {
      message: i18next.t("zod.error.no.match.password"),
      path: ["confirmPassword"],
    }
  );

export type ConfirmForgotPasswordFormType = z.infer<
  typeof ConfirmForgotPasswordSchema
>;

// Props
type ConfirmForgotPasswordFormProps = {
  submit: (data: ConfirmForgotPasswordFormType) => void;
};

const ConfirmForgotPasswordForm = ({
  submit,
}: ConfirmForgotPasswordFormProps) => {
  const form = useForm<ConfirmForgotPasswordFormType>({
    resolver: zodResolver(ConfirmForgotPasswordSchema),
    defaultValues: {
      code: "",
      newPassword: "",
      confirmPassword: "",
    },
  });

  const { t } = useTranslation();
  const { errors } = form.formState;

  const onSubmit = async (formData: ConfirmForgotPasswordFormType) => {
    try {
      await submit(formData);
    } catch (error) {}
  };

  const clearErrors = () => {
    form.clearErrors();
  };

  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)} className={`space-y-6`}>
        <div className="space-y-4">
          <FormField
            control={form.control}
            name="code"
            rules={{ required: true }}
            render={({ field }) => (
              <FormItem>
                <FormLabel>{t("form.input.code")}</FormLabel>
                <FormControl>
                  <PinInputs {...field} state={"error"} />
                </FormControl>
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="newPassword"
            rules={{ required: true }}
            render={({ field }) => (
              <FormItem>
                <FormLabel>{t("form.input.new.password")}</FormLabel>
                <FormControl>
                  <InputField
                    placeholder={t("form.placeholder.password")}
                    id="newPassword"
                    type="password"
                    state={errors.newPassword ? "error" : undefined}
                    onFocus={clearErrors}
                    extra="bg-transparent"
                    {...field}
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="confirmPassword"
            rules={{ required: true }}
            render={({ field }) => (
              <FormItem>
                <FormLabel>{t("form.input.confirm.password")}</FormLabel>
                <FormControl>
                  <InputField
                    placeholder={t("form.placeholder.password")}
                    id="confirmPassword"
                    type="password"
                    state={errors.confirmPassword ? "error" : undefined}
                    onFocus={clearErrors}
                    extra="bg-transparent"
                    {...field}
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
        </div>

        <GenericButton
          type="submit"
          text={t("auth.confirm.forgot.password.action")}
          extra="w-full"
        />
      </form>
    </Form>
  );
};

export default ConfirmForgotPasswordForm;
