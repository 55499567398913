import * as z from "zod";

import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";

import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "../../../providers/form";
import { useTranslation } from "react-i18next";
import { shallowEqual, useSelector } from "react-redux";
import Checkbox from "widgets/shared/inputs/checkbox";
import { accountSiteList } from "store/appSelectors";
import GenericButton from "widgets/shared/buttons/generic";
// Schema
const UserSitesSchema = z.object({
  classes: z.array(z.string()),
});

export type UserSitesFormType = z.infer<typeof UserSitesSchema>;

// Props
type UserSitesFormProps = {
  submit: (data: UserSitesFormType) => void;
  onClose: () => void;
  defaultValues?: UserSitesFormType;
  values?: UserSitesFormType;
  loading?: boolean;
  sites: any[];
};

const UserSitesForm = ({
  submit,
  onClose,
  defaultValues,
  values,
  loading,
  sites,
}: UserSitesFormProps) => {
  const form = useForm<UserSitesFormType>({
    resolver: zodResolver(UserSitesSchema),
    defaultValues: {
      classes: [],
    },
    values,
  });

  const { t } = useTranslation();
  const { errors, isDirty } = form.formState;
  // const sites = useSelector(accountSiteList, shallowEqual);

  const onSubmit = async (formData: UserSitesFormType) => {
    try {
      await submit(formData);
    } catch (error) {
      console.error("Error submitting form:", error);
    }
  };

  const selectAll = () => {
    const allSiteIds = sites.map((site) => site.id);
    form.setValue("classes", allSiteIds, { shouldDirty: true });
  };

  const deselectAll = () => {
    form.setValue("classes", [], { shouldDirty: true });
  };

  const clearErrors = () => {
    form.clearErrors();
  };

  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)} className=" space-y-2">
        <div className="flex items-end justify-between">
          <div className="flex gap-2">
            <GenericButton
              text={"Select All"}
              size="sm"
              type="button"
              variant="outline"
              onClick={selectAll}
              rounded="rounded-xl"
            />
            <GenericButton
              text={"Unselect All"}
              size="sm"
              type="button"
              variant="outline"
              onClick={deselectAll}
              rounded="rounded-xl"
            />
          </div>

          <GenericButton
            text={"Link Sites"}
            size="sm"
            type="submit"
            disabled={!isDirty}
            rounded="rounded-xl"
          />
        </div>
        <div className="grid grid-cols-1 gap-3 space-y-6 ">
          <FormField
            control={form.control}
            name="classes"
            rules={{ required: false }}
            render={({ field }) => (
              <FormItem>
                <FormControl>
                  <div className="grid w-full grid-cols-1 gap-x-1  md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4">
                    {sites.map((item, index) => (
                      <div
                        key={item.id}
                        className={`p-2 ${
                          Math.floor(index / 4) % 2 === 1
                            ? "bg-white"
                            : "bg-gray-50"
                        }`}
                      >
                        <Checkbox
                          key={item.id}
                          id={`checkbox-${item.id}`}
                          color="blue"
                          labelKey={item.name}
                          checked={(field.value || []).includes(item.id)}
                          valueKey={item.id}
                          onChange={() => {
                            if ((field.value || []).includes(item.id)) {
                              field.onChange(
                                field.value.filter((id) => id !== item.id)
                              );
                            } else {
                              field.onChange([...field.value, item.id]);
                            }
                          }}
                        />
                      </div>
                    ))}
                  </div>
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
        </div>
      </form>
    </Form>
  );
};

export default UserSitesForm;
