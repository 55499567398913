import { useDispatch, useSelector } from "react-redux";
import type { RootState, AppDispatch } from "../store/store";

// Use throughout your app instead of plain `useDispatch` and `useSelector`
export const useAppDispatch = useDispatch.withTypes<AppDispatch>();
export const useAppSelector = useSelector.withTypes<RootState>();

interface PieChartData {
  usage: number;
  remaining?: number; // Optional since remaining is not always needed
}

export const stripPrefixIncl = (
  text: string | undefined,
  prefix: string
): string => {
  if (!text) return "";
  const regex = new RegExp(`.*${prefix}`);
  return text.replace(regex, "").toUpperCase();
};

export const getPieChartUsage = (
  usage: number,
  dailyLimit?: number
): number => {
  if (dailyLimit) {
    const usagePercentage = (usage / dailyLimit) * 100;
    const remainingPercentage = 100 - usagePercentage;

    return usagePercentage;
    // return {
    //   usage: usagePercentage,
    //   remaining: remainingPercentage >= 0 ? remainingPercentage : 0,
    // };
  } else {
    // No daily limit, the pie chart should only reflect the usage
    return usage > 100 ? 100 : usage;
  }
};

export const getDashboardDeviceCount = (
  filterDropdown: { label: string; value: string[] }[],
  metrics: Record<string, number>
): Record<string, number> => {
  const result: Record<string, number> = {};

  filterDropdown.forEach(({ label, value }) => {
    const formattedLabel = label.toLowerCase().replace(/\s+/g, "_");
    result[formattedLabel] = value.reduce((total, key) => {
      return total + (metrics[key] || 0);
    }, 0);
  });

  return result;
};
