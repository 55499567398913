import * as z from "zod";
import { useForm, useFieldArray } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "../../../../providers/form";
import InputField from "widgets/shared/inputs/inputField";
import { useTranslation } from "react-i18next";
import GenericButton from "widgets/shared/buttons/generic";
import ComboBox from "widgets/shared/inputs/combobox";
import LabeledField from "providers/labeledField";
import CircleButton from "widgets/shared/buttons/circle-button";
import { MdAdd, MdClose } from "react-icons/md";
import Checkbox from "widgets/shared/inputs/checkbox";

// Schema definition for the form
const OtherRateSchema = z.object({
  other: z
    .array(
      z.object({
        name: z.string().min(1, "Name is required"),
        rate: z.number().gte(0, "Rate must be a positive number"),
        type: z.enum(["water", "electricity", "sewerage", "other"]),
      })
    )
    .optional(),
});

export type OtherRateFormType = z.infer<typeof OtherRateSchema>;

// Props for the form
type OtherRateFormProps = {
  submit: (data: OtherRateFormType) => void;
  onClose: () => void;
  defaultValues?: OtherRateFormType;
  values?: OtherRateFormType;
  loading?: boolean;
  disabled?: boolean;
};

const OtherRateForm = ({
  submit,
  onClose,
  defaultValues,
  values,
  loading,
  disabled,
}: OtherRateFormProps) => {
  const { t } = useTranslation();

  const form = useForm<OtherRateFormType>({
    resolver: zodResolver(OtherRateSchema),
    defaultValues: defaultValues,
  });

  const { control, handleSubmit, formState, reset } = form;
  const { isDirty, errors } = formState;

  const { fields, append, remove } = useFieldArray({
    control,
    name: "other", // Manage array for other rates
  });

  const onSubmit = async (formData: OtherRateFormType) => {
    try {
      await submit(formData);
      reset(formData);
    } catch (error) {
      console.error("Error submitting form:", error);
    }
  };

  const handleAddRate = () => {
    append({ name: "", rate: 0 });
  };

  const clearErrors = () => {
    form.clearErrors();
  };

  return (
    <Form {...form}>
      <form onSubmit={handleSubmit(onSubmit)} className="space-y-2">
        {/* Rate type selector */}
        <div className="flex w-full flex-row justify-end pt-1">
          {!disabled && (
            <div className="flex gap-2">
              <CircleButton
                // label="Add"
                icon={MdAdd}
                onClick={handleAddRate}
                disabled={disabled}
                size="sm"
                variant="outline"
              />
            </div>
          )}
        </div>

        <>
          {fields.map((item, index) => (
            <>
              <div className="flex justify-end">
                {!disabled && (
                  <CircleButton
                    icon={MdClose}
                    onClick={() => {
                      remove(index);
                      handleSubmit(onSubmit)();
                    }}
                    disabled={disabled}
                    variant="outline"
                    size="xxs"
                  />
                )}
              </div>
              <div key={item.id} className="grid grid-cols-2 gap-2">
                <FormField
                  control={control}
                  name={`other.${index}.name`}
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel className="text-txt-xs text-primary">
                        {"Name:"}
                      </FormLabel>
                      <FormControl>
                        <InputField
                          sizes="sm"
                          placeholder={t("form.step.start.placeholder")}
                          id={`name-${index}`}
                          type="text"
                          {...field}
                          disabled={disabled}
                          onFocus={clearErrors}
                          onBlur={() => handleSubmit(onSubmit)()}
                        />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />

                <FormField
                  control={control}
                  name={`other.${index}.rate`}
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel className="text-txt-xs text-primary">
                        {"Rate:"}
                      </FormLabel>
                      <FormControl>
                        <InputField
                          sizes="sm"
                          placeholder={t("form.step.end.placeholder")}
                          id={`rate-${index}`}
                          type="number"
                          {...field}
                          disabled={disabled}
                          onBlur={() => handleSubmit(onSubmit)()}
                        />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
                <div className="col-span-2 gap-2">
                  <FormField
                    control={control}
                    name={`other.${index}.type`}
                    render={({ field }) => (
                      <FormItem>
                        <FormLabel className="text-txt-xs text-primary">
                          {"Type:"}
                        </FormLabel>
                        <FormControl>
                          <ComboBox
                            items={[
                              { label: "Water", value: "water" },
                              { label: "Electricity", value: "electricity" },
                              { label: "Sewerage", value: "sewerage" },
                              { label: "Other", value: "other" },
                            ]}
                            placeholder={t("form.placeholder.device.model")}
                            labelKey="label"
                            valueKey="value"
                            id="model"
                            type="text"
                            sizes="sm"
                            value={field.value}
                            onChange={(value) => {
                              field.onChange(value);
                              // handleSubmit(onSubmit)();
                            }}
                            // {...field}
                            onFocus={clearErrors}
                            clearIcon={false}
                            disabled={disabled}
                          />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                </div>
              </div>
              <div className="my-1 h-px w-full bg-gray-200 dark:bg-white/20" />
            </>
          ))}
        </>
      </form>
    </Form>
  );
};

export default OtherRateForm;
