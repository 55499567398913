import makeApiRequest from "./clients/apiRequest";
import { gateway } from "./clients/baseClient";
import { versionApiPath } from "./common";
import { ENDPOINTS } from "./endpoints";
import { ResponseData } from "./types/api";
import { DashboardReqBody } from "./types/dashboard";

export async function getDashboard(
  sections: DashboardReqBody
): Promise<ResponseData> {
  const data = {
    endpoint: `${versionApiPath}/${ENDPOINTS.DASHBOARD.GET}`,
    body: sections,
  };
  return await makeApiRequest(
    {
      url: ``,
      method: "post",
      data: data,
    },
    gateway,
    false
  );
}
