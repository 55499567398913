import React from "react";
import { Routes, Route, Navigate, useLocation } from "react-router-dom";
import { Portal, PortalManager } from "@chakra-ui/portal";
import Navbar from "components/navbar";
import Sidebar from "components/sidebar";
import Footer from "components/footer/Footer";
import routes, { allroutes } from "routes";

export default function Admin(props: { [x: string]: any }) {
  const { ...rest } = props;
  const location = useLocation();
  const [open, setOpen] = React.useState(true);
  const [hovered, setHovered] = React.useState(false);
  const [currentRoute, setCurrentRoute] = React.useState("Main Dashboard");
  const [activeNavbar, setActiveNavbar] = React.useState<any>(null);

  React.useEffect(() => {
    window.addEventListener("resize", () =>
      window.innerWidth < 1200 ? setOpen(false) : setOpen(true)
    );
  }, []);
  React.useEffect(() => {
    getActiveRoute([...routes, ...allroutes]);
    // eslint-disable-next-line
  }, [location.pathname]);
  // functions for changing the states from components
  const getActiveRoute = (routes: RoutesType[]): string => {
    let activeRoute = "Default Brand Text";
    for (let i = 0; i < routes.length; i++) {
      if (routes[i].collapse) {
        let collapseActiveRoute = getActiveRoute(routes[i].items);
        if (collapseActiveRoute !== activeRoute) {
          return collapseActiveRoute;
        }
      } else {
        if (
          window.location.href.indexOf(routes[i].layout + routes[i].path) !== -1
        ) {
          setCurrentRoute(routes[i].name);
          setActiveNavbar(routes[i].navbar);
        }
      }
    }
    return activeRoute;
  };
  const getActiveNavbar = (routes: RoutesType[]): boolean => {
    let activeNavbar = false;
    for (let i = 0; i < routes.length; i++) {
      if (routes[i].collapse) {
        let collapseActiveNavbar = getActiveNavbar(routes[i].items);
        if (collapseActiveNavbar !== activeNavbar) {
          return collapseActiveNavbar;
        }
      } else {
        if (
          window.location.href.indexOf(routes[i].layout + routes[i].path) !== -1
        ) {
          return routes[i].secondary;
        }
      }
    }
    return activeNavbar;
  };
  const getRoutes = (routes: RoutesType[]): any => {
    return routes.map((prop, key) => {
      if (prop.layout === "/admin") {
        return (
          <Route path={`${prop.path}`} element={prop.component} key={key} />
        );
      }
      if (prop.collapse) {
        return getRoutes(prop.items);
      }
      return null;
    });
  };
  document.documentElement.dir = "ltr";

  return (
    <div className="flex h-full w-full bg-background-100 dark:bg-background-900">
      <Sidebar
        open={open}
        hovered={hovered}
        setHovered={setHovered}
        mini={props.mini}
        onClose={() => setOpen(false)}
      />
      {/* Navbar & Main Content */}
      <div className="flex h-screen w-full flex-col font-dm dark:bg-navy-900">
        {/* Routes */}

        <Portal>
          <Navbar
            onOpenSidenav={() => setOpen(!open)}
            brandText={currentRoute}
            secondary={getActiveNavbar(routes)}
            navbarType={activeNavbar}
            theme={props.theme}
            setTheme={props.setTheme}
            hovered={hovered}
            mini={props.mini}
            setMini={props.setMini}
            {...rest}
          />
        </Portal>
        {/* Main Content */}

        <main
          className={`mx-2.5 flex-none overflow-auto transition-all dark:bg-navy-900 md:pr-2 ${
            activeNavbar ? "z-10" : ""
          } ${
            props.mini === false
              ? "xl:ml-[270px]"
              : props.mini === true && hovered === true
              ? "xl:ml-[313px]"
              : "ml-0 xl:ml-[142px]"
          } `}
        >
          <div className="mx-auto pt-36 sm:px-2 sm:pt-20 md:px-2 lg:pt-24">
            <Routes>
              {getRoutes(routes)}
              {getRoutes(allroutes)}
              <Route
                path="/"
                element={<Navigate to="/admin/dashboards" replace />}
              />
            </Routes>
          </div>
        </main>
        <div
          className={`relative py-6 ${
            props.mini === false
              ? "xl:ml-[250px]"
              : props.mini === true && hovered === true
              ? "xl:ml-[313px]"
              : "ml-0 xl:ml-[142px]"
          }`}
        >
          <Footer />
        </div>
      </div>
    </div>
  );
}
