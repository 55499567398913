import { useDisclosure } from "@chakra-ui/hooks";
import { Modal, ModalBody, ModalContent, ModalOverlay } from "@chakra-ui/modal";
import { PortalManager } from "@chakra-ui/portal";
import { getSignedUrl } from "api/admin";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { setLoading } from "store/appSlice";

const PhotoButton = (props: { imageSrc: string; imageKey?: string }) => {
  const dispatch = useDispatch();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [imageUrl, setImageUrl] = useState<string | null>(null);

  const handleClick = async () => {
    if (props.imageKey && !props.imageKey.startsWith("https")) {
      dispatch(setLoading(true));
      const { data, isLoading } = await getSignedUrl(props.imageKey);
      dispatch(setLoading(isLoading));
      setImageUrl(data?.signedUrl || props.imageSrc); // Use the signed URL or fallback to the original imageSrc
    } else {
      setImageUrl(props.imageKey);
    }
    onOpen(); // Open the modal after setting the image URL
  };

  return (
    <div>
      {props.imageKey ? (
        <div
          className="flex h-16 w-16 cursor-pointer justify-center rounded-xl bg-cover bg-center"
          style={{ backgroundImage: `url(${props.imageSrc})` }}
          onClick={handleClick}
        ></div>
      ) : null}
      <PortalManager zIndex={2000}>
        <Modal
          isOpen={isOpen}
          onClose={onClose}
          closeOnOverlayClick
          onOverlayClick={onClose}
        >
          <ModalOverlay className="bg-[#000] !opacity-30" />
          <ModalContent
            zIndex={2500}
            className="top-[6vh] mx-2 rounded-md bg-white outline-none ring-0 sm:top-[12vh] sm:!m-auto sm:!max-w-[60%] md:!max-w-[50%] lg:!max-w-[45%] xl:!max-w-[35%] 2xl:!max-w-[25%]"
          >
            <ModalBody className="p-4">
              {imageUrl && (
                <img
                  src={imageUrl}
                  alt="Modal content"
                  style={{ width: "100%", borderRadius: "20px" }}
                />
              )}
            </ModalBody>
          </ModalContent>
        </Modal>
      </PortalManager>
    </div>
  );
};

export default PhotoButton;
