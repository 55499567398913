import { t } from "i18next";

const Footer = () => {
  return (
    <div className="flex w-full flex-col items-center justify-between px-1 lg:flex-row lg:px-12">
      <p className="text-center text-txt-sm">
        <span className="mb-4 text-center text-txt-sm text-link dark:text-darkLink">
          ©{new Date().getFullYear()} {t("footer.all.rights")}
        </span>
      </p>
      <div>
        <ul className="flex flex-wrap items-center justify-center gap-3 sm:flex-nowrap md:gap-10">
          <li>
            <a
              target="blank"
              href="https://www.atomservices.io/contact#SUPPORT"
              className="text-txt-sm text-link dark:text-darkLink"
            >
              {t("footer.support")}
            </a>
          </li>
          <li>
            <a
              target="blank"
              href="https://www.atomservices.io/privacy-policy"
              className="text-txt-sm text-link dark:text-darkLink"
            >
              {t("footer.privacy.policy")}
            </a>
          </li>
          <li>
            <a
              target="blank"
              href="https://www.atomservices.io/terms-and-conditions"
              className="text-txt-sm text-link dark:text-darkLink"
            >
              {t("footer.terms.use")}
            </a>
          </li>
          <li>
            <a
              target="blank"
              href="https://www.atomservices.io/contact"
              className="text-txt-sm text-link dark:text-darkLink"
            >
              {t("footer.contact.us")}
            </a>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default Footer;
