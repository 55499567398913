import { forwardRef, InputHTMLAttributes } from "react";

interface CheckboxProps extends InputHTMLAttributes<HTMLInputElement> {
  labelKey?: string;
  valueKey?: any;
  sizes?: "xl" | "lg" | "md";
  loading?: boolean;
  checked?: boolean;
  color?:
    | "red"
    | "blue"
    | "green"
    | "yellow"
    | "orange"
    | "teal"
    | "navy"
    | "lime"
    | "cyan"
    | "pink"
    | "purple"
    | "amber"
    | "indigo"
    | "gray";
  id?: string;
  name?: string;
  defaultChecked?: boolean;
  disabled?: boolean;
  onChange?: any;
  onClick?: any;
  extra?: string;
  labelPosition?: "left" | "right";
}

const Radio = forwardRef<HTMLInputElement, CheckboxProps>(
  (
    {
      labelKey,
      valueKey,
      sizes = "lg",
      loading = false,
      readOnly,
      checked,
      disabled,
      extra,
      onChange,
      onClick,
      defaultChecked,
      color,
      id,
      name,
      labelPosition = "right",
      ...rest
    },
    ref
  ) => {
    const sizeClasses = {
      xl: "h-6 w-6 before:h-4 before:w-4",
      lg: "h-5 w-5 before:h-3 before:w-3",
      md: "h-4 w-4 before:h-2 before:w-2",
    };

    const labelClasses = {
      xl: "text-txt-lg text-primary",
      lg: "text-txt-lg text-primary",
      md: "text-txt-sm text-primary",
    };

    return (
      <div className={`flex w-full items-center gap-2 ${labelClasses[sizes]}`}>
        {labelPosition === "left" && labelKey && <span>{labelKey}</span>}
        <input
          defaultChecked={defaultChecked ?? false}
          id={id}
          name={name}
          type="radio"
          onClick={(e) => {
            e.stopPropagation();
          }}
          onChange={(e) => {
            onChange(e);
          }}
          checked={checked}
          value={valueKey}
          disabled={disabled || loading}
          readOnly={readOnly}
          className={`before:contet[""] relative ${
            sizeClasses[sizes]
          } cursor-pointer appearance-none rounded-full border !border-gray-200
           transition-all duration-[0.2s] before:absolute before:left-[50%] before:top-[3px] 
         before:translate-x-[-50%] before:rounded-full before:transition-all before:duration-[0.2s] disabled:cursor-not-allowed disabled:bg-gray-50 dark:!border-gray-800
           ${extra}
           ${
             color === "red"
               ? "checked:!border-red-500 checked:before:!bg-red-500 dark:checked:!border-red-400 dark:checked:before:!bg-red-400"
               : color === "blue"
               ? "checked:!border-blue-500 checked:before:!bg-blue-500 dark:checked:!border-blue-400 dark:checked:before:!bg-blue-400"
               : color === "green"
               ? "checked:!border-green-500 checked:before:!bg-green-500 dark:checked:!border-green-400 dark:checked:before:!bg-green-400"
               : color === "yellow"
               ? "checked:!border-yellow-500 checked:before:!bg-yellow-500 dark:checked:!border-yellow-400 dark:checked:before:!bg-yellow-400"
               : color === "orange"
               ? "checked:!border-orange-500 checked:before:!bg-orange-500 dark:checked:!border-orange-400 dark:checked:before:!bg-orange-400"
               : color === "teal"
               ? "checked:!border-teal-500 checked:before:!bg-teal-500 dark:checked:!border-teal-400 dark:checked:before:!bg-teal-400"
               : color === "navy"
               ? "checked:!border-navy-500 checked:before:!bg-navy-500 dark:checked:!border-navy-400 dark:checked:before:!bg-navy-400"
               : color === "lime"
               ? "checked:!border-lime-500 checked:before:!bg-lime-500 dark:checked:!border-lime-400 dark:checked:before:!bg-lime-400"
               : color === "cyan"
               ? "checked:!border-cyan-500 checked:before:!bg-cyan-500 dark:checked:!border-cyan-400 dark:checked:before:!bg-cyan-400"
               : color === "pink"
               ? "checked:!border-pink-500 checked:before:!bg-pink-500 dark:checked:!border-pink-400 dark:checked:before:!bg-pink-400"
               : color === "purple"
               ? "checked:!border-purple-500 checked:before:!bg-purple-500 dark:checked:!border-purple-400 dark:checked:before:!bg-purple-400"
               : color === "amber"
               ? "checked:!border-amber-500 checked:before:!bg-amber-500 dark:checked:!border-amber-400 dark:checked:before:!bg-amber-400"
               : color === "indigo"
               ? "checked:!border-indigo-500 checked:before:!bg-indigo-500 dark:checked:!border-indigo-400 dark:checked:before:!bg-indigo-400"
               : color === "gray"
               ? "checked:!border-gray-500 checked:before:!bg-gray-500 dark:checked:!border-gray-400 dark:checked:before:!bg-gray-400"
               : "checked:!border-primary checked:before:!bg-primary dark:checked:!border-darkPrimary dark:checked:before:!bg-darkPrimary"
           } `}
          {...rest}
        />
        {labelPosition === "right" && labelKey && <span>{labelKey}</span>}
      </div>
    );
  }
);

export default Radio;
