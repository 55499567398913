import { InputHTMLAttributes, forwardRef } from "react";

interface CheckboxProps extends InputHTMLAttributes<HTMLInputElement> {
  id?: string;
  labelKey?: string;
  valueKey?: any;
  checked?: boolean;
  extra?: string;
  onChange: (value: any) => void;
  sizes?: "lg" | "md";
  returnBoolean?: boolean;
  loading?: boolean;
  color?:
    | "red"
    | "blue"
    | "green"
    | "yellow"
    | "orange"
    | "teal"
    | "navy"
    | "lime"
    | "cyan"
    | "pink"
    | "purple"
    | "amber"
    | "indigo"
    | "gray";
  [x: string]: any;
  labelPosition?: "left" | "right";
}

const Switch = forwardRef<HTMLInputElement, CheckboxProps>(
  (
    {
      extra,
      checked,
      onChange,
      color,
      id,
      labelKey,
      valueKey,
      sizes = "lg",
      returnBoolean = false,
      loading = false,
      disabled,
      readOnly,
      labelPosition = "right",
      ...rest
    },
    ref
  ) => {
    let sizeClass = "";
    let circleSizeH = "";
    let circleSizeW = "";
    let translateChecked = "";

    switch (sizes) {
      case "lg":
        sizeClass = "h-5 w-10";
        circleSizeH = "h-4";
        circleSizeW = "w-4";
        translateChecked = "checked:before:translate-x-[22px]";
        break;
      case "md":
        sizeClass = "h-4 w-8";
        circleSizeH = "h-3";
        circleSizeW = "w-3";
        translateChecked = "checked:before:translate-x-[18px]";
        break;
    }

    const labelClasses = {
      xl: "text-txt-lg text-primary",
      lg: "text-txt-lg text-primary",
      md: "text-txt-sm text-primary",
    };

    return (
      <div className={`flex w-full items-center gap-2 ${labelClasses[sizes]}`}>
        {labelPosition === "left" && labelKey && <span>{labelKey}</span>}
        <input
          id={id}
          type="checkbox"
          onClick={(e) => {
            e.stopPropagation();
          }}
          onChange={(e) => {
            const value = returnBoolean ? e.target.checked : e.target.value;
            onChange(value);
          }}
          checked={checked}
          value={valueKey}
          disabled={disabled || loading}
          readOnly={readOnly}
          className={`relative ${sizeClass} appearance-none rounded-[20px] bg-[#e0e5f2] outline-none transition duration-[0.5s]
          before:absolute before:top-[50%] before:${circleSizeH} before:${circleSizeW} before:translate-x-[2px] before:translate-y-[-50%] before:rounded-[20px]
         before:bg-white
          hover:cursor-pointer
          dark:bg-white/5
          ${translateChecked}
          ${
            color === "red"
              ? "checked:border-none checked:bg-red-500 dark:checked:bg-red-400"
              : color === "blue"
              ? "checked:border-none checked:bg-brand-50 dark:checked:bg-blue-400"
              : color === "green"
              ? "checked:border-none checked:bg-green-500 dark:checked:bg-green-400"
              : color === "yellow"
              ? "checked:border-none checked:bg-yellow-500 dark:checked:bg-yellow-400"
              : color === "orange"
              ? "checked:border-none checked:bg-orange-500 dark:checked:bg-orange-400"
              : color === "teal"
              ? "checked:border-none checked:bg-teal-500 dark:checked:bg-teal-400"
              : color === "navy"
              ? "checked:border-none checked:bg-navy-500 dark:checked:bg-navy-400"
              : color === "lime"
              ? "checked:border-none checked:bg-lime-500 dark:checked:bg-lime-400"
              : color === "cyan"
              ? "checked:border-none checked:bg-cyan-500 dark:checked:bg-cyan-400"
              : color === "pink"
              ? "checked:border-none checked:bg-pink-500 dark:checked:bg-pink-400"
              : color === "purple"
              ? "checked:border-none checked:bg-purple-500 dark:checked:bg-purple-400"
              : color === "amber"
              ? "checked:border-none checked:bg-amber-500 dark:checked:bg-amber-400"
              : color === "indigo"
              ? "checked:border-none checked:bg-indigo-500 dark:checked:bg-indigo-400"
              : color === "gray"
              ? "checked:border-none checked:bg-gray-500 dark:checked:bg-gray-400"
              : `checked:bg-brand-50  dark:checked:bg-brand-400`
          } ${extra}`}
          name="weekly"
          {...rest}
        />
        {labelPosition === "right" && labelKey && <span>{labelKey}</span>}
      </div>
    );
  }
);

export default Switch;
