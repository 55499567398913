import * as z from "zod";

import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";

import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "../../../../providers/form";
import InputField from "widgets/shared/inputs/inputField";
import { useTranslation } from "react-i18next";
import i18next from "i18next";
import GenericButton from "widgets/shared/buttons/generic";
import { useEffect } from "react";
// Schema
const TemperatureConfigurationSchema = z
  .object({
    triggerLow: z.any(),
    triggerHigh: z.any(),
  })
  .refine((data) => data.triggerHigh > data.triggerLow, {
    message: i18next.t("zod.error.trigger.temperature"),
    path: ["triggerHigh"], // This will point to the triggerHigh field in case of an error
  });

export type TemperatureConfigurationFormType = z.infer<
  typeof TemperatureConfigurationSchema
>;

// Props
type TemperatureConfigurationFormProps = {
  submit: (data: TemperatureConfigurationFormType) => void;
  onClose: () => void;
  defaultValues?: TemperatureConfigurationFormType;
  values?: TemperatureConfigurationFormType;
  loading?: boolean;
  disabled?: boolean;
};

const TemperatureConfigurationForm = ({
  submit,
  onClose,
  defaultValues,
  values,
  loading,
  disabled,
}: TemperatureConfigurationFormProps) => {
  const form = useForm<TemperatureConfigurationFormType>({
    resolver: zodResolver(TemperatureConfigurationSchema),
    defaultValues: {
      triggerLow: "",
      triggerHigh: "",
    },
    values,
  });

  const { t } = useTranslation();
  const { errors, isDirty } = form.formState;

  const clearErrors = () => {
    form.clearErrors();
  };

  // Automatically submit the form if valid
  const onSubmit = async (formData: TemperatureConfigurationFormType) => {
    try {
      await submit(formData);
    } catch (error) {
      console.error("Error submitting form:", error);
    }
  };

  useEffect(() => {
    if (disabled && isDirty) {
      form.reset(values || defaultValues);
    }
  }, [disabled, isDirty, form, values, defaultValues]);

  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)} className=" space-y-2">
        <div className="grid grid-cols-1 gap-2 md:grid-cols-2 md:gap-5">
          <FormField
            control={form.control}
            name="triggerLow"
            rules={{ required: false }}
            render={({ field }) => (
              <FormItem>
                <FormLabel className="text-secondary">
                  {t("form.input.trigger.low", { context: "temperature" })}
                </FormLabel>
                <FormControl>
                  <InputField
                    sizes={"md"}
                    placeholder={t("form.placeholder.trigger.low", {
                      context: "temperature",
                    })}
                    id="trigger-low"
                    type="number"
                    state={errors.triggerLow ? "error" : undefined}
                    onFocus={clearErrors}
                    disabled={disabled}
                    {...field}
                    color="secondary"
                    variant="solid"
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />

          <FormField
            control={form.control}
            name="triggerHigh"
            rules={{ required: false }}
            render={({ field }) => (
              <FormItem>
                <FormLabel className="text-secondary">
                  {t("form.input.trigger.high", { context: "temperature" })}
                </FormLabel>
                <FormControl>
                  <InputField
                    sizes={"md"}
                    placeholder={t("form.placeholder.trigger.high", {
                      context: "temperature",
                    })}
                    id="trigger-high"
                    type="number"
                    state={errors.triggerHigh ? "error" : undefined}
                    onFocus={clearErrors}
                    disabled={disabled}
                    {...field}
                    color="secondary"
                    variant="solid"
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
        </div>

        {isDirty && !disabled && (
          <div className="flex w-full flex-col pt-4">
            <div className="flex justify-end">
              <GenericButton
                text={t("button.action.save.changes")}
                disabled={!isDirty}
                loading={loading || false}
                type="submit"
                color="secondary"
              />
            </div>
          </div>
        )}
      </form>
    </Form>
  );
};

export default TemperatureConfigurationForm;
