export enum MenuRoutePaths {
  DASHBOARD = "/dashboards/default",
  SITES = "/sites",
  USERS = "/users",
  PROFILE = "/profile",
  DEVICES = "/devices",
  REPORTS = "/reports",
}

export enum AuthRoutePaths {
  SIGN_IN = "/auth/sign-in",
  SIGN_UP = "/auth/sign-up/default",
  FORGOT_PASSWORD = "/auth/forgot-password",
}
